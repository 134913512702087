import { gql } from "@apollo/client";

const ADD_TEAM_MEMBER = gql`
  mutation createTeamMember($team: Int!, $member: Int!, $role: String) {
    createTeamMember(
      createTeamMemberInput: { team: $team, member: $member, role: $role }
    ) {
      id
    }
  }
`;
export default ADD_TEAM_MEMBER;
