import React, { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, useLocation } from "react-router-dom";
import { Collapse } from "@material-ui/core";
import { ReactSVG } from "react-svg";
import useAuth from "src/hooks/useAuth";
import { useTranslation } from "react-i18next";

type subItem = {
  title: string;
  path: string;
  new: string;
  icon?: any;
};

interface SidebarSubItemsProps {
  subitems: subItem[] | any;
  openSideBar: boolean;
  openSubItem: boolean;
  setOpenSubItem?: Dispatch<SetStateAction<boolean>>;
  disable?: any;
}

const SidebarSubItems: React.FC<SidebarSubItemsProps> = ({
  subitems,
  openSubItem,
  setOpenSubItem,
  openSideBar,
  disable,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const auth = useAuth();
  const isActive = useMemo(
    () => (pathname: string) => pathname?.split("?")[0] === location.pathname,
    [location.pathname]
  );
  const memoizedSetOpenSubItem = useMemo(
    () => setOpenSubItem,
    [setOpenSubItem]
  );

  useEffect(() => {
    memoizedSetOpenSubItem(subitems.some((item) => isActive(item.path)));
  }, [subitems, memoizedSetOpenSubItem]);
  const handleEdit = (data) => {
    const doesStringExist = location.pathname.includes(data);
    return doesStringExist;
  };

  return openSideBar ? (
    <Collapse
      in={openSubItem}
      timeout={40}
      unmountOnExit
      sx={{
        "& .MuiCollapse-wrapperInner::before": {
          content: "''",
          borderLeft: "3px solid #86909F",
          width: "2px",
          height: "calc(100% - 35px)",
          position: "absolute",
          top: 0,
          left: "-15px",
        },
        "& .MuiCollapse-wrapperInner": {
          marginLeft: "32px",
          position: "relative",
        },
      }}
    >
      {subitems.map((item, index) =>
        item?.path === "/dashboard/my-platforms" &&
        auth?.user?.role === "superAdmin" ? (
          ""
        ) : (
          <ListItem
            button
            component={Link}
            to={item.path}
            key={index}
            disabled={disable}
            sx={{
              "& .MuiListItemText-root::before": openSideBar && {
                content: "''",
                borderRadius: "0px 0px 0px 8px",
                borderBottom: "3px solid #86909F",
                borderLeft: "3px solid #86909F",
                width: "13px",
                height: "18px",
                position: "absolute",
                top: 5,
                left: "-15px",
              },
              my: "10px",
              padding: "8px 12px",
              background:
                isActive(item.path) ||
                isActive(item?.new) ||
                handleEdit(item?.new)
                  ? "#FF5733"
                  : "transparent",
              color:
                isActive(item.path) ||
                isActive(item?.new) ||
                handleEdit(item?.new)
                  ? "#FFFFFF"
                  : "#86909F",
              borderRadius: "8px",
              height: "40px",
              "&:hover": {
                background: "#FF5733",
                color: "#FFFFFF",
              },
            }}
          >
            <ListItemText primary={t(item.title)} />
          </ListItem>
        )
      )}
    </Collapse>
  ) : (
    <Collapse
      in={openSubItem}
      timeout={40}
      unmountOnExit
      sx={{
        "& .MuiCollapse-wrapperInner::before": {
          content: "''",
          borderLeft: "2px solid #86909F",
          width: "2px",
          height: "calc(100% - 35px)",
          position: "absolute",
          top: 0,
          left: "-10px",
        },
        "& .MuiCollapse-wrapperInner": {
          marginLeft: "25px",
          position: "relative",
        },
      }}
    >
      {subitems.map((item, index) => (
        <ListItem
          button
          component={Link}
          to={item.path}
          key={index}
          disabled={disable}
          sx={{
            "& .MuiListItemText-root::before": {
              content: "''",
              borderRadius: "0px 0px 0px 8px",
              borderBottom: "2px solid #86909F",
              borderLeft: "2px solid #86909F",
              width: "13px",
              height: "14px",
              position: "absolute",
              top: 5,
              left: "-10px",
            },
            my: "10px",
            padding: "8px 8px",
            color: "#86909F",
            borderRadius: "8px",
            height: "35px",
            "&:hover": {
              background: "#FFFFFF",
              // color: "#FFFFFF",
            },
          }}
        >
          <ListItemText className="react-svg collaps-menu">
            <ReactSVG
              src={item?.icon}
              style={{ marginTop: "5px" }}
              beforeInjection={(svg) => {
                svg.classList.add("svg-class");
                svg.classList.add(
                  isActive(item.path) ? "svg-class-name12" : "svg-class-name11"
                );
                svg.children[0].setAttribute(
                  "style",
                  openSubItem || isActive ? "fill: #FFFF" : "fill: #86909F"
                );
              }}
            />
          </ListItemText>
        </ListItem>
      ))}
    </Collapse>
  );
};

export default SidebarSubItems;
