import { gql } from "@apollo/client";

const PLATFORM_RANKING_CATEGORIES = gql`
  query platformLeagueCategories($filter: ListLeagueInput) {
    platformLeagueCategories(filter: $filter) {
      id
      title
      leagueCategory {
        category {
          id
          name
          type
          gender
          uniqueCode
          maxTournaments
          activeFrom
          activeTo
          sort
          ageLimit
          ageLimitType
          selectStage
          countries
        }
      }
    }
  }
`;

export default PLATFORM_RANKING_CATEGORIES;
