import { gql } from "@apollo/client";

const CREATE_CATEGORY_LICENSE = gql`
  mutation createTournamentCategoryLicense(
    $tournamentCategory: Int!
    $license: Int!
  ) {
    createTournamentCategoryLicense(
      createTournamentCategoryLicenseInput: {
        tournamentCategory: $tournamentCategory
        license: $license
      }
    ) {
      id
    }
  }
`;

export default CREATE_CATEGORY_LICENSE;
