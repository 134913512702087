import { gql } from "@apollo/client";

const MEMBERS = gql`
  query ($id: ID!) {
    league(id: $id) {
      id
      members {
        member {
          email
          name
          surname
          dob
          email
          nation
          phone
          role
          id
        }
      }
    }
  }
`;

export default MEMBERS;
