import { gql } from "@apollo/client";

const DELETE_SETTING_CATEGORY = gql`
  mutation removeCategorySetting($id: Int!) {
    removeCategorySetting(id: $id) {
      createdAt
    }
  }
`;

export default DELETE_SETTING_CATEGORY;
