import {
  useEffect,
  useState,
  useMemo,
  useCallback,
  Fragment,
  useRef,
} from "react";
import type { FC } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  ListItem,
  ListItemText,
  Menu,
  Autocomplete,
  TextField,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { useLazyQuery, useQuery } from "@apollo/client";
import Cookies from "js-cookie";

import type { Theme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useAuth from "../../hooks/useAuth";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import { ChevronLeft } from "@material-ui/icons";
import {
  ALLLEAGUESNAMES,
  LEAGUE_BY_ID,
  ORGANIZATION_PLATFORMS,
} from "src/graphql/queries";
import { ReactSVG } from "react-svg";
import { USER_ROLES, rowPerPage } from "src/constants";
import { ChevronRight } from "@material-ui/icons";
import ChevronDown from "src/icons/ChevronDown";
import { PLATEFORM } from "src/graphql/queries/plateform";
import useDebouncedTerm from "src/hooks/useDebouncedTerm";
import PLATFORM_BY_ID from "src/graphql/queries/getplatformById";

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const sections2 = [
  {
    title: "Super Data",
    icon: "/images/tournament-icon.svg",
    rolesAllowed: [USER_ROLES.SUPER_ADMIN, USER_ROLES.SUPER_ADMIN],
    subItems: [
      {
        title: "Platforms",
        path: "/dashboard/platforms",
        new: "/dashboard/platforms/new",
        icon: "/images/super-admin.svg",
      },
      {
        title: "Leagues",
        path: "/dashboard/leagues",
        icon: "/images/leagues.svg",
      },
      {
        title: "Organization Accounts",
        path: "/dashboard/organization-account",
        new: "/dashboard/organization-account/new",
        icon: "/images/user-gear.svg",
      },
      {
        title: "Users",
        path: "/dashboard/users",
        new: "/dashboard/users/new",
        icon: "/images/user-gear.svg",
      },
      {
        title: "All Tournaments",
        path: "/dashboard/allTournaments",
        icon: "/images/tournaments.svg",
      },
      {
        title: "Sports",
        path: "/dashboard/sports",
        new: "/dashboard/sports/new",
        icon: "/images/tournaments.svg",
      },
      {
        title: "Disciplines",
        path: "/dashboard/disciplines",
        new: "/dashboard/disciplines/new",
        icon: "/images/tournaments.svg",
      },
      {
        title: "Third-party licences",
        path: "/dashboard/third-party-license",
        new: "/dashboard/third-party-license/new",
        icon: "/images/tournaments.svg",
      },
      {
        title: "Partners",
        path: "/dashboard/partners",
        new: "/dashboard/partners/new",
        icon: "/images/partners.svg",
      },
      // {
      //   title: "Pricing",
      //   path: "/dashboard/pricing",
      //   icon: "/images/tournaments.svg",
      // },
      // {
      //   title: "Subscriptions",
      //   path: "/dashboard/subscriptions",
      //   icon: "/images/tournaments.svg",
      // },
    ],
  },
];

const sections = [
  // {
  //   title: "Related Data",
  //   rolesAllowed: [USER_ROLES.FEDERATION],
  //   icon: "/images/tournament-icon.svg",
  //   subItems: [
  //     {
  //       title: "Platforms",
  //       path: "/dashboard/platforms",
  //     },
  //     {
  //       title: "Leagues",
  //       path: "/dashboard/leagues",
  //     },
  //   ],
  // },
  {
    title: "Data",
    icon: "/images/data-icon.svg",
    rolesAllowed: [
      USER_ROLES.ADMIN,
      USER_ROLES.SUPER_ADMIN,
      USER_ROLES.ORGANIZER,
      USER_ROLES.FEDERATION,
      USER_ROLES.ATHELETE,
      USER_ROLES.USER,
    ],
    subItems: [
      // {
      //   title: "Tournaments",
      //   path: "/dashboard/tournaments",
      //   icon: "/images/license.svg",
      // },
      {
        title: "Pending Tournaments",
        path: "/dashboard/pending-tournaments",
        icon: "/images/license.svg",
      },

      {
        title: "Athletes",
        path: "/dashboard/athletes",
        new: "/dashboard/athletes/new",
        icon: "/images/athlete-icon.svg",
      },

      // {
      //   title: "Purchased Licenses",
      //   path: "/dashboard/purchased-licenses",
      //   new: "/dashboard/purchased-licenses/new",

      //   icon: "/images/license.svg",
      // },
      // {
      //   title: "Teams",
      //   path: "/dashboard/teams",
      //   new: "/dashboard/teams/new",
      //   icon: "/images/teams.svg",
      // },
      // {
      //   title: "Coaches",
      //   path: "/dashboard/coaches",
      //   new: "/dashboard/coaches/new",
      //   icon: "/images/coaches.svg",
      // },
      // {
      //   title: "Clubs",
      //   path: "/dashboard/clubs",
      //   new: "/dashboard/clubs/new",

      //   icon: "/images/clubs.svg",
      // },

      // {
      //   title: "News",
      //   path: "/dashboard/news",
      //   new: "/dashboard/news/new",

      //   icon: "/images/news.svg",
      // },
    ],
  },
  {
    title: "Financials",
    icon: "/images/financials-icon.svg",
    rolesAllowed: [
      USER_ROLES.ADMIN,
      USER_ROLES.SUPER_ADMIN,
      USER_ROLES.ORGANIZER,
      USER_ROLES.FEDERATION,
      USER_ROLES.ATHELETE,
      USER_ROLES.USER,
    ],
    disable: true,
    subItems: [
      {
        title: "Accounting",
        path: "/dashboard/accounting",
        icon: "/images/license.svg",
      },
    ],
  },
  {
    title: "Extra",
    icon: "/images/extra-icon.svg",
    rolesAllowed: [
      USER_ROLES.ADMIN,
      USER_ROLES.SUPER_ADMIN,
      USER_ROLES.ORGANIZER,
      USER_ROLES.FEDERATION,
      USER_ROLES.ATHELETE,
      USER_ROLES.USER,
    ],
    disable: true,
    subItems: [
      {
        title: "Live scoring",
        path: "/dashboard/live-score",
        icon: "/images/member.svg",
      },
      {
        title: "Live stream",
        path: "/dashboard/live-stream",
        icon: "/images/member.svg",
      },
    ],
  },
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { getOrganizationId } = useAuth();
  const auth = useAuth();
  const [platformId, setPlatformId] = useState<any>("");
  const [leagueId, setLeagueId] = useState<any>("");
  const [organizationId, setOrganizationId] = useState<any>("");
  useEffect(() => {
    if (auth?.platformId) {
      setPlatformId(auth?.platformId);
    }
    if (auth?.leagueId) {
      setLeagueId(auth.leagueId);
    }
    if (auth?.organizationId) {
      setOrganizationId(auth?.organizationId);
    }
  }, [auth?.platformId, auth.leagueId, auth?.organizationId]);
  const [TopSections, setTopSections] = useState<any>([]);
  const [platformSection, setPlatformSection] = useState([]);
  const [platformSectionArray, setPlatformSectionArray] = useState([]);
  useEffect(() => {
    if (platformId && auth.user.role) {
      setPlatformSectionArray([
        {
          title: "Platform",
          rolesAllowed:
            auth?.user?.role !== "superAdmin" &&
            auth?.user?.role !== "admin" &&
            parseInt(process.env.REACT_APP_TOURNATED_PLATFORM_ID) ===
              auth?.platformId
              ? [USER_ROLES.SUPER_ADMIN]
              : [
                  USER_ROLES.FEDERATION,
                  USER_ROLES.SUPER_ADMIN,
                  USER_ROLES.MANAGER,
                ],

          icon: "/images/tournament-icon.svg",
          subItems: [
            {
              title: "Platform Settings",
              path: `/dashboard/platforms/settings/${platformId}`,
              icon: "/images/leagues.svg",
            },

            {
              title: "Team",
              path: "/dashboard/platform-members",
              new: "/dashboard/platform-members/new",
              icon: "/images/user-gear.svg",
            },
            // {
            //   title: "Leagues",
            //   path: "/dashboard/platform-leagues",
            //   icon: "/images/user-gear.svg",
            // },
            {
              title: "Organization Accounts",
              path: "/dashboard/platform-organization-account",
              icon: "/images/user-gear.svg",
            },
            {
              title: "Users",
              path: "/dashboard/platform-users",
              new: "/dashboard/platform-users/new",
              icon: "/images/user-gear.svg",
            },
            {
              title: "Coaches",
              path: "/dashboard/coaches",
              new: "/dashboard/coaches/new",
              icon: "/images/coaches.svg",
            },
            {
              title: "Tournaments",
              path: "/dashboard/platform-tournaments",
              icon: "/images/tournaments.svg",
            },
            {
              title: "Sports",
              path: "/dashboard/platform-sports",
              // new: "/dashboard/sports/new",
              icon: "/images/tournaments.svg",
            },
            {
              title: "Disciplines",
              path: "/dashboard/platform-disciplines",
              new: "/dashboard/platform-disciplines/new",
              icon: "/images/tournaments.svg",
            },
            {
              title: "Third-party licences",
              path: "/dashboard/platform-third-party-license",
              new: "/dashboard/third-party-license/new",
              icon: "/images/tournaments.svg",
            },
            {
              title: "Partners",
              path: "/dashboard/platform-partners",
              new: "/dashboard/platform-partners/new",
              icon: "/images/partners.svg",
            },
            {
              title: "Posts",
              path: "/dashboard/posts",
              new: "/dashboard/posts/new",

              icon: "/images/news.svg",
            },
            {
              title: "Clubs",
              path: "/dashboard/clubs",
              new: "/dashboard/clubs/new",

              icon: "/images/clubs.svg",
            },
            // {
            //   title: "Purchased Licenses",
            //   path: "/dashboard/purchased-licenses",
            //   new: "/dashboard/purchased-licenses/new",

            //   icon: "/images/license.svg",
            // },
            {
              title: "Teams",
              path: "/dashboard/teams",
              new: "/dashboard/teams/new",
              icon: "/images/teams.svg",
            },

            // {
            //   title: "Clubs",
            //   path: "/dashboard/clubs",
            //   new: "/dashboard/clubs/new",

            //   icon: "/images/clubs.svg",
            // },
            {
              title: "Banners",
              path: "/dashboard/banners",
              new: "/dashboard/banners/new",

              icon: "/images/clubs.svg",
            },
            {
              title: "Documents",
              path: "/dashboard/documents",
              new: "/dashboard/documents/new",
              icon: "/images/partners.svg",
            },
            // {
            //   title: "Platforms",
            //   path: "/dashboard/organization-platforms",
            //   icon: "/images/user-gear.svg",
            // },

            // {
            //   title: "Platform Members",
            //   path: "/dashboard/platform-members",
            //   new: "/dashboard/organization-account/new",
            //   icon: "/images/user-gear.svg",
            // },
          ],
        },
        {
          title: "Organization",
          rolesAllowed: [
            USER_ROLES.FEDERATION,
            USER_ROLES.SUPER_ADMIN,
            USER_ROLES.MANAGER,
          ],

          icon: "/images/federations.svg",
          subItems: [
            {
              title: "Organization details",
              path: `/dashboard/organization-account/settings/${organizationId}`,
              icon: "/images/leagues.svg",
            },
            {
              title: "Platforms",
              path: `/dashboard/my-platforms`,
              icon: "/images/leagues.svg",
            },

            {
              title: "Leagues",
              path: "/dashboard/platform-leagues",
              icon: "/images/user-gear.svg",
            },
            {
              title: "Team Members",
              path: "/dashboard/leagues-members",
              new: "/dashboard/leagues-members/new",

              icon: "/images/member.svg",
            },

            {
              title: "Tournaments",
              path: "/dashboard/tournaments",
              icon: "/images/license.svg",
            },
            {
              title: "Licenses",
              path: "/dashboard/licenses",
              new: "/dashboard/licenses/new",
              icon: "/images/license.svg",
            },

            {
              title: "Purchased Licenses",
              path: "/dashboard/purchased-licenses",
              new: "/dashboard/purchased-licenses/new",

              icon: "/images/license.svg",
            },
            {
              title: "Clubs",
              path: "/dashboard/organzaition-clubs",
              new: "/dashboard/organzaition-clubs/new",

              icon: "/images/clubs.svg",
            },
          ],
        },

        {
          title: "Control Panel",
          icon: "/images/generel-settings-icon.svg",
          rolesAllowed: [USER_ROLES.FEDERATION, USER_ROLES.SUPER_ADMIN],
          subItems: [
            {
              title: "Instructions",
              path: "/dashboard",
              icon: "/images/leagues.svg",
            },
            {
              title: "Create League",
              path: "/dashboard/leagues/new",
              new: "/dashboard/leagues/new",
              icon: "/images/leagues.svg",
            },
          ],
        },
      ]);
      setTopSections([
        {
          title: "League",
          icon: "/images/leagues-icon.svg",
          rolesAllowed: [USER_ROLES.FEDERATION, USER_ROLES.SUPER_ADMIN],
          subItems: [
            {
              title: "League Settings",
              path: `/dashboard/leagues/settings/${leagueId}`,
              icon: "/images/leagues.svg",
            },

            {
              title: "Tournament Categories",
              path: "/dashboard/tournament-categories",
              new: "/dashboard/tournament-categories/new",

              icon: "/images/tournaments.svg",
            },
            {
              title: "Ranking Categories",
              path: "/dashboard/ranking-categories",
              new: "/dashboard/ranking-categories/new",

              icon: "/images/rank-cats.svg",
            },
            {
              title: "Ranking Points",
              path: "/dashboard/ranking-points",

              icon: "/images/ranking.svg",
            },

            {
              title: "Rankings",
              path: "/dashboard/rankings",
              icon: "/images/ranking.svg",
            },
            {
              title: "Rankings History",
              path: "/dashboard/rankings-history",
              icon: "/images/partners.svg",
            },
            {
              title: "Tournament Results",
              path: "/dashboard/tournament-results",
              icon: "/images/partners.svg",
            },
            {
              title: "Partners",
              path: "/dashboard/league-partners",
              new: "/dashboard/league-partners/new",
              icon: "/images/partners.svg",
            },

            // {
            //   title: "Gallery",
            //   path: "/dashboard/gallery",
            //   new: "/dashboard/gallery/new",
            //   icon: "/images/partners.svg",
            // },
          ],
        },
      ]);
    }
  }, [platformId, leagueId, auth.user.role]);

  const navigate = useNavigate();
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const {
    user,
    getLeagueId,
    getLeaguesData,
    getPlatformId,
    getSportId,
    getPlatformLanguages,
  } = useAuth();

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>([]);
  // const { loading, data } = useQuery(LEAGUES, {
  //   variables: {
  //     filter: {},
  //   },
  // });
  const {
    loading,
    refetch,
    data: leaguesdata,
  } = useQuery(ALLLEAGUESNAMES, {
    // skip:
    //   auth?.user?.role !== "superAdmin" &&
    //   auth?.user?.role !== "admin" &&
    //   parseInt(process.env.REACT_APP_TOURNATED_PLATFORM_ID) ===
    //     auth?.platformId,
    variables: {
      filter: {
        platform: auth.platformId,
      },
    },
    onCompleted: (data) => {
      // if (
      //   data.allLeaguesNames.length === 0 &&
      //   auth?.user?.role === "federation"
      // )
      //   navigate("/dashboard");
      let findedLeague = data?.allLeaguesNames?.find(
        (item) => item?.id === auth?.leagueId
      );
      if (!auth?.leagueId || !findedLeague) {
        if (location.pathname.includes("/dashboard/leagues/settings")) {
          if (data?.allLeaguesNames[0]?.id !== undefined) {
            navigate(
              `/dashboard/leagues/settings/${data?.allLeaguesNames[0]?.id}`
            );
          }
        }
        // ////////////// ***************** this is temporarhy solution for removing the  selected league from the if the role is not superAdmin and admin and the platform tournated selected

        // if (
        //   auth?.user?.role !== "superAdmin" &&
        //   auth?.user?.role !== "admin" &&
        //   parseInt(process.env.REACT_APP_TOURNATED_PLATFORM_ID) ===
        //     auth?.platformId
        // ) {
        //   getLeagueId(undefined);
        // } else {
        getLeagueId(data?.allLeaguesNames[0]?.id);
        // }
      }
    },
  });
  const [leagueIndex, setLeagueIndex] = useState<number | string>(
    auth.leagueId
  );
  const [userSectionsState, setUserSections] = useState(sections);
  const [adminSectionsState, setAdminSections] = useState(sections2);

  // const getFilteredLeagues = async () => {
  //   let variables;

  //   variables = {
  //     filter: {
  //       platform: auth.platformId,
  //     },
  //   };
  //   try {
  //     const { data } = await getLeagues({
  //       variables,
  //     });
  //     console.log("data allLeagueNames", data.allLeaguesNames);
  //     getLeaguesData(data.allLeaguesNames);

  //     setdata(data);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };
  useEffect(() => {
    if (!loading) {
      if (leaguesdata?.allLeaguesNames.length > 0) {
        setData(leaguesdata);
      } else {
        setData([]);
        getLeagueId(undefined);
      }
    }
  }, [leaguesdata, loading]);
  useEffect(() => {
    if (auth.platformId) {
      refetch();
    }
  }, [auth.platformId, auth?.leagueId]);
  useEffect(() => {
    if (leaguesdata?.allLeaguesNames.length > 0) {
      if (auth.leagueId) {
        let findedLeague = leaguesdata?.allLeaguesNames?.find(
          (item) => item?.id === auth?.leagueId
        );
        getSportId(findedLeague?.sports?.id);
      }
    }
  }, [leaguesdata?.allLeaguesNames, auth.leagueId]);

  const selectUserSections = useCallback((user) => {
    if (user) {
      const filteredArray = sections.filter((element: any) =>
        element?.rolesAllowed?.some((subElement) =>
          subElement?.includes(user.role)
        )
      );

      setUserSections(filteredArray);
    } else {
      setUserSections(sections);
    }
  }, []);

  const selectAdminSections = useCallback((user) => {
    if (user) {
      const filteredArray = sections2.filter((element: any) =>
        element?.rolesAllowed?.some((subElement) =>
          subElement?.includes(user.role)
        )
      );

      setAdminSections(filteredArray);
    } else {
      setAdminSections(sections2);
    }
  }, []);
  const selectCommonSections = useCallback((user) => {
    if (user) {
      const filteredArray = TopSections.filter((element: any) =>
        element?.rolesAllowed?.some((subElement) =>
          subElement?.includes(user.role)
        )
      );

      setTopSections(filteredArray);
    } else {
      setTopSections(TopSections);
    }
  }, []);
  // const selectPlatformSections = useCallback((user) => {
  //   if (user) {
  //     const filteredArray = platformSectionArray.filter((element: any) =>
  //       element?.rolesAllowed?.some((subElement) =>
  //         subElement?.includes(user.role)
  //       )
  //     );
  //     console.log("platformSectionArray ###", platformSectionArray);
  //     console.log("user ##", user);
  //     console.log("filteredArray @@", filteredArray);
  //     setPlatformSection(filteredArray);
  //   } else {
  //     setPlatformSection(platformSection);
  //   }
  // }, []);
  useEffect(() => {
    if (user && platformSectionArray.length > 0) {
      const filteredArray = platformSectionArray.filter((element: any) =>
        element?.rolesAllowed?.some((subElement) =>
          subElement?.includes(user.role)
        )
      );
      setPlatformSection(filteredArray);
    } else {
      setPlatformSection(platformSection);
    }
  }, [user, platformSectionArray]);

  useEffect(() => {
    selectUserSections(auth.user);
    selectAdminSections(auth.user);
    selectCommonSections(auth.user);
  }, [
    selectUserSections,
    selectAdminSections,
    selectCommonSections,
    auth.user,
  ]);

  const selectedLeague: any = useMemo(() => {
    if (auth?.leagueId) {
      return data?.allLeaguesNames?.find(
        (league: any) => league.id === auth.leagueId
      );
    }
  }, [auth?.leagueId, data?.allLeaguesNames]);
  const handleClick = () => {
    setOpen(!open);
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const handleGetId = (id: number | string, index?: number) => {
    setLeagueIndex(id);
    if (location.pathname.includes("/dashboard/leagues/settings")) {
      navigate(`/dashboard/leagues/settings/${id}`);
    }
    getLeagueId(id);
  };

  useEffect(() => {
    if (
      !auth.leagueId &&
      data?.allLeaguesNames?.length > 0 &&
      auth?.user?.role === "federation"
    ) {
      // navigate("/dashboard/leagues/instructions");
      // navigate("/dashboard");

      // getLeagueId(data?.allLeaguesNames[0].id);
      setLeagueIndex(data?.allLeaguesNames[0].id);
    }
  }, [auth.leagueId, data]);

  const [sideBarWidth, setSideBarWidth] = useState(270);
  const [openSideBarState, setOpenSideBarState] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenSideBar = () => {
    setOpenSideBarState(!openSideBarState);
    if (!openSideBarState) {
      setSideBarWidth(270);
    } else {
      setSideBarWidth(88);
    }
  };
  const [platforms, setPlatforms] = useState<any>([]);

  // const [getPlatforms, { loading: loader, data: OrganizationPlatforms }] =
  //   useLazyQuery(ORGANIZATION_PLATFORMS);
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 1280) {
        setOpenSideBarState(true);
        setSideBarWidth(270);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [platformIndex, setPlatformIndex] = useState<number | string>(
    auth.leagueId
  );
  const selectedPlatform: any = useMemo(() => {
    if (auth?.platformId) {
      return platforms?.find(
        (platform: any) => platform.id === auth.platformId
      );
    }
  }, [auth?.platformId, platforms]);

  // useEffect(() => {
  //   let variables;
  //   if (auth.user.role === "federation") {
  //     variables = {
  //       filter: {
  //         federation: auth.user.id,
  //       },
  //     };
  //   } else {
  //     variables = {
  //       filter: {},
  //     };
  //   }
  //   if (auth.user) {
  //     getPlatforms({
  //       variables: variables,
  //     });
  //   }
  // }, [auth.user.role]);

  // useEffect(() => {
  //   setPlatforms(
  //     OrganizationPlatforms?.organizationPlatforms.map((item: any) => {
  //       return {
  //         title: item?.platform?.title,
  //         id: item?.platform?.id,
  //       };
  //     })
  //   );
  // }, [OrganizationPlatforms]);

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [searchTitle, setSearchTitle] = useState("");
  const debouncedSearch = useDebouncedTerm(searchTitle, 600);

  const { loading: isLoadingPlatforms, data: plateform } = useQuery(PLATEFORM, {
    variables: {
      page: page,
      limit: rowPerPage,
      filter: {
        ...(debouncedSearch && { title: debouncedSearch }),
        status: "active",
      },
    },
    skip: auth?.user?.role !== "superAdmin", // Change here
    onCompleted: (data) => {
      setHasMore(data?.platforms?.platforms?.length > 0);
      setPlatforms((prevPlatforms) => {
        const newPlatforms = data?.platforms?.platforms?.filter(
          (platform) =>
            !prevPlatforms.find(
              (prevPlatform) => prevPlatform?.id === platform?.id
            )
        );
        return [...prevPlatforms, ...newPlatforms];
      });
    },
  });

  // for preselect platform
  const { loading: isLoadingParamTournament } = useQuery(PLATEFORM, {
    // Skip if platform is not included in platforms list
    skip:
      !platformId ||
      platforms.length === 0 ||
      platforms?.find((tournament: any) => tournament?.id === platformId),
    variables: {
      filter: {
        id: platformId,
      },
    },
    onCompleted: (data) => {
      setPlatforms(
        (prevPlatforms) =>
          [...prevPlatforms, ...data?.platforms?.platforms] || []
      );
    },
  });

  const { loading: isLoadingOrgPlatforms, data: OrganizationPlatforms } =
    useQuery(ORGANIZATION_PLATFORMS, {
      skip: auth?.user?.role === "superAdmin", // Change here
      variables: {
        filter: {
          federation: auth.user.id,
        },
      },
      onCompleted: (data) => {
        const activePlatforms = data?.organizationPlatforms
          .filter((item) => item?.platform?.status === "active")
          .map((item) => ({
            title: item?.platform?.title,
            id: item?.platform?.id,
          }));

        setPlatforms(activePlatforms);
      },
    });
  useEffect(() => {
    if (auth?.user.role !== "superAdmin") {
      if (auth?.platformId) {
        const organizationData =
          OrganizationPlatforms?.organizationPlatforms?.find(
            (item) => item?.platform?.id === auth?.platformId
          );
        getOrganizationId(organizationData?.federation?.id);
      }
    }
  }, [OrganizationPlatforms, auth?.platformId]);

  const handleGetPlatformId = (id: number | string, index?: number) => {
    setPlatformIndex(id);
    getPlatformId(id);
    // leaguesData(id);
  };

  // useEffect(() => {
  //   if (platforms?.length > 0) {
  //     let findPlatform = platforms?.find((item) => item?.id === auth?.platformId);
  //     if (!auth?.platformId || !findPlatform?.id) {
  //       getPlatformId(platforms[0].id);
  //     }
  //   }
  // }, [platforms]);

  const observer: any = useRef();

  const lastOptionElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (
          entries[0].isIntersecting &&
          hasMore &&
          !isLoadingPlatforms &&
          !isLoadingOrgPlatforms
        ) {
          setPage((page) => page + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingPlatforms, isLoadingOrgPlatforms]
  );

  // ////////////// ***************** this is temporarhy solution for removing the platform and hide the side bar if the role is not superAdmin and admin and the platform tournated selected
  // useEffect(() => {
  //   if (
  //     auth?.user?.role !== "superAdmin" &&
  //     auth?.user?.role !== "admin" &&
  //     parseInt(process.env.REACT_APP_TOURNATED_PLATFORM_ID) === auth?.platformId
  //   ) {
  //     navigate("/dashboard");
  //     setData([]);
  //   }
  // }, [auth?.platformId]);

  /////////////////////// update organization into auth if there is no league in the sidebar  /////////////////////

  useQuery(PLATFORM_BY_ID, {
    variables: {
      platformId: auth?.platformId,
    },
    onCompleted: (platformData) => {
      if (auth?.user?.role === "superAdmin") {
        if (auth.leagueId === undefined) {
          getOrganizationId(
            platformData?.organizationPlatformByFields?.federation?.id
          );
          if (
            location.pathname.includes(
              "/dashboard/organization-account/settings"
            )
          ) {
            navigate(
              `/dashboard/organization-account/settings/${platformData?.organizationPlatformByFields?.federation?.id}`
            );
          }
        }
      }
      getPlatformLanguages(
        platformData?.organizationPlatformByFields?.platform?.language
      );
    },
  });
  /////////////////////// update organization into auth if there is a league is the sidebar  /////////////////////

  useQuery(LEAGUE_BY_ID, {
    variables: {
      id: auth?.leagueId,
    },
    onCompleted: (data) => {
      if (auth?.user?.role === "superAdmin") {
        getOrganizationId(data?.league?.owner?.federation?.id);
        if (
          location.pathname.includes("/dashboard/organization-account/settings")
        ) {
          navigate(
            `/dashboard/organization-account/settings/${data?.league?.owner?.federation?.id}`
          );
        }
      }
    },
  });

  const content = (
    <Fragment>
      {/* SideBar header */}
      <Box
        sx={{
          position: "sticky",
          display: lgUp ? "block" : "none",
          background: "#ffff",
          top: 0,
          zIndex: 9,
          px: "24px",
          pt: "24px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            right: "-8px",
            top: 23,
            width: "28px",
            height: "28px",
            padding: "6px",
            borderRadius: "8px",
            gap: "8px",
            border: "1px solid #EFEFF0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
            backgroundColor: "#FFFFFF",
            cursor: "pointer",
          }}
          onClick={handleOpenSideBar}
        >
          {!openSideBarState ? (
            <ChevronRight fontSize="small" />
          ) : (
            <ChevronLeft fontSize="small" />
          )}
        </Box>
        <RouterLink to="/dashboard">
          <Box
            sx={{
              pb: 2,
              borderBottom: "1px solid #EFEFF0",
              mb: 2,
            }}
          >
            {openSideBarState ? (
              <ReactSVG src="/images/darkLogo.svg" />
            ) : (
              <ReactSVG src="/images/tournated-icon.svg" />
            )}
          </Box>
        </RouterLink>
      </Box>
      <Scrollbar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            px: openSideBarState ? "24px" : "8px",
            // overflowY: "auto",
          }}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={platforms || []}
            // options={[]}
            sx={{
              display: {
                sm: "none",
                xs: "block",
              },
              mt: "0px",
              zIndex: 9999,
              position: "relative",
              "& .MuiOutlinedInput-root": {
                paddingRight: "30px !important",
                height: "40px",
                mb: 1,
                padding: "10px 12px 10px 12px",
                borderRadius: "8px",
                background: "#FAFAFA",
                border: "1px solid #EDF1F6",
                color: "#0A2540",
              },
              "& .MuiOutlinedInput-input": {
                padding: "0px !important",
              },
              "& .MuiOutlinedInput-root .MuiAutocomplete-input[placeholder]": {
                padding: "0px !important",
              },
            }}
            noOptionsText="No league found"
            onChange={(e, value) => {
              handleGetPlatformId(value?.id);
              Cookies.remove("lang");
            }}
            popupIcon={<ChevronDown />}
            value={selectedPlatform ? selectedPlatform : null}
            clearIcon={null}
            getOptionLabel={(option) =>
              option?.title.charAt(0).toUpperCase() + option?.title.slice(1)
            }
            renderOption={(props, item) => (
              <li {...props} key={item?.key} ref={lastOptionElementRef}>
                <ListItemText>{item?.title}</ListItemText>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                placeholder="Select platform"
                onChange={(e) => {
                  if (e.target.value === "") setHasMore(true);
                  setSearchTitle(e.target.value);
                  setPage(0);
                }}
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {isLoadingPlatforms ||
                      isLoadingParamTournament ||
                      isLoadingOrgPlatforms ? (
                        <CircularProgress
                          color="inherit"
                          size={16}
                          sx={{
                            background: "#ffff",
                            zIndex: "99",
                            marginRight: "-14px",
                            borderRadius: "50%",
                          }}
                        />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
          />
          {data?.allLeaguesNames?.length == 0 || !data?.allLeaguesNames ? (
            <Box>
              {openSideBarState ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={data?.allLeaguesNames || []}
                  sx={{
                    mt: "0px",
                    "& .MuiOutlinedInput-root": {
                      paddingRight: "30px !important",
                      height: "40px",
                      padding: "10px 12px 10px 12px",
                      borderRadius: "8px",
                      background: "#FAFAFA",
                      border: "1px solid #EDF1F6",
                      color: "#0A2540",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "0px !important",
                    },
                    "& .MuiOutlinedInput-root .MuiAutocomplete-input[placeholder]":
                      {
                        padding: "0px !important",
                      },
                  }}
                  noOptionsText="No Leagues found"
                  onChange={(e, value) => {
                    if (value?.id) {
                      handleGetId(value?.id);
                    }
                  }}
                  popupIcon={<ChevronDown />}
                  value={selectedLeague ? selectedLeague : null}
                  clearIcon={null}
                  getOptionLabel={(option) => option?.title}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Select League"
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {loading ? (
                              <CircularProgress
                                color="inherit"
                                size={16}
                                sx={{
                                  background: "#ffff",
                                  zIndex: "99",
                                  marginRight: "-14px",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        ),
                      }}
                    />
                  )}
                />
              ) : (
                <Box style={{ display: "flex" }}>
                  <Button
                    sx={{
                      width: openSideBarState ? "100%" : "45px",
                      mx: "auto",
                      minWidth: "unset",
                      mt: 3,
                      background: open ? "#FF5733" : "#FAFAFA",
                      border: "1px solid #EFEFF0",
                      borderRadius: "8px",
                    }}
                    id="tournaments-menu"
                    aria-controls="tournaments-menu2"
                    onClick={() => setOpen(true)}
                  >
                    <ChevronDown
                      style={{ color: open ? "#FFFF" : "#86909F" }}
                    />
                  </Button>
                  <Menu
                    id="tournaments-menu2"
                    aria-labelledby="tournaments-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    sx={{
                      "& .MuiPaper-root": {
                        left: "86px !important",
                        padding: "10px",
                      },
                    }}
                  >
                    {data?.allLeaguesNames?.map((item, index) => (
                      <ListItem
                        key={index}
                        button
                        sx={{
                          "& .MuiTypography-root": {
                            fontWeight: "500",
                            color:
                              item.id === leagueIndex ? "#FF5733" : "#86909F",
                          },
                        }}
                        // onClick={() => handleGetId(item.id) , handleGetCount(item.id)}
                        onClick={() => {
                          handleGetId(item.id);
                          handleClick();
                        }}
                      >
                        <ListItemText
                          primary={item.title}
                          sx={{
                            color: "#0A2540",
                            fontWeight: "500",
                            justifyContent: "flex-start",
                            textAlign: "left",
                            paddingLeft: "16px",
                            textTransform: "none",
                            width: "100%",
                          }}
                        />
                      </ListItem>
                    ))}
                  </Menu>
                </Box>
              )}
              {adminSectionsState.map((section) => (
                <NavSection
                  key={section.title}
                  pathname={location.pathname}
                  openSideBarState={openSideBarState}
                  {...section}
                />
              ))}

              {
                // auth?.user?.role === "superAdmin" ||
                // auth?.user?.role === "admin" ||
                // parseInt(process.env.REACT_APP_TOURNATED_PLATFORM_ID) !==
                //   auth?.platformId
                //   ?
                platformSection.map((section) => (
                  <NavSection
                    key={section.title}
                    pathname={location.pathname}
                    openSideBarState={openSideBarState}
                    {...section}
                  />
                ))
                // : null
              }

              {/* {user?.role !== "superAdmin" && (
              <Box sx={{ textAlign: "center" }}>
                <Button
                  size="small"
                  color="primary"
                  startIcon={
                    <ReactSVG
                      src="/images/addw.svg"
                      style={{
                        marginTop: "4px",
                        paddingLeft: "5px",
                      }}
                    />
                  }
                  sx={{
                    height: "40px",
                    mt: 1,
                    textTransform: "uppercase",
                  }}
                  fullWidth
                  variant="contained"
                  onClick={() => navigate("/dashboard/leagues/new")}
                >
                  Create New League
                </Button>
              </Box>
            )} */}
            </Box>
          ) : (
            <Box>
              {openSideBarState ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={data?.allLeaguesNames || []}
                  sx={{
                    mt: "0px",
                    "& .MuiOutlinedInput-root": {
                      paddingRight: "30px !important",
                      height: "40px",
                      padding: "10px 12px 10px 12px",
                      borderRadius: "8px",
                      background: "#FAFAFA",
                      border: "1px solid #EDF1F6",
                      color: "#0A2540",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "0px !important",
                    },
                    "& .MuiOutlinedInput-root .MuiAutocomplete-input[placeholder]":
                      {
                        padding: "0px !important",
                      },
                  }}
                  noOptionsText="No Leagues found"
                  onChange={(e, value) => {
                    if (value?.id) {
                      handleGetId(value?.id);
                    }
                  }}
                  popupIcon={<ChevronDown />}
                  value={selectedLeague ? selectedLeague : null}
                  clearIcon={null}
                  getOptionLabel={(option) =>
                    option?.status === "Inactive"
                      ? `${option?.title} (${option?.status})`
                      : option?.title
                  }
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      key={option?.id}
                      style={{
                        color: option?.status === "Inactive" ? "#c4c4c4" : "",
                      }}
                    >
                      {option?.title}{" "}
                      {option?.status === "Inactive" && `(${option?.status})`}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Select League"
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {loading ? (
                              <CircularProgress
                                color="inherit"
                                size={16}
                                sx={{
                                  background: "#ffff",
                                  zIndex: "99",
                                  marginRight: "-14px",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        ),
                      }}
                    />
                  )}
                />
              ) : (
                <Box style={{ display: "flex" }}>
                  <Button
                    sx={{
                      width: openSideBarState ? "100%" : "45px",
                      mx: "auto",
                      minWidth: "unset",
                      mt: 3,
                      background: open ? "#FF5733" : "#FAFAFA",
                      border: "1px solid #EFEFF0",
                      borderRadius: "8px",
                    }}
                    id="tournaments-menu"
                    aria-controls="tournaments-menu2"
                    onClick={() => setOpen(true)}
                  >
                    <ChevronDown
                      style={{ color: open ? "#FFFF" : "#86909F" }}
                    />
                  </Button>
                  <Menu
                    id="tournaments-menu2"
                    aria-labelledby="tournaments-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    sx={{
                      "& .MuiPaper-root": {
                        left: "86px !important",
                        padding: "10px",
                      },
                    }}
                  >
                    {data?.allLeaguesNames?.map((item, index) => (
                      <ListItem
                        key={index}
                        button
                        sx={{
                          "& .MuiTypography-root": {
                            fontWeight: "500",
                            color:
                              item.id === leagueIndex ? "#FF5733" : "#86909F",
                          },
                        }}
                        // onClick={() => handleGetId(item.id) , handleGetCount(item.id)}
                        onClick={() => {
                          handleGetId(item.id);
                          handleClick();
                        }}
                      >
                        <ListItemText
                          primary={item.title}
                          sx={{
                            color: "#0A2540",
                            fontWeight: "500",
                            justifyContent: "flex-start",
                            textAlign: "left",
                            paddingLeft: "16px",
                            textTransform: "none",
                            width: "100%",
                          }}
                        />
                      </ListItem>
                    ))}
                  </Menu>
                </Box>
              )}
              {adminSectionsState.map((section) => (
                <NavSection
                  key={section.title}
                  pathname={location.pathname}
                  openSideBarState={openSideBarState}
                  sx={{
                    "& + &": {
                      mt: 3,
                    },
                  }}
                  {...section}
                />
              ))}
              {
                // auth?.user?.role === "superAdmin" ||
                // auth?.user?.role === "admin" ||
                // parseInt(process.env.REACT_APP_TOURNATED_PLATFORM_ID) !==
                //   auth?.platformId
                //   ?
                platformSection.map((section) => (
                  <NavSection
                    key={section.title}
                    pathname={location.pathname}
                    openSideBarState={openSideBarState}
                    {...section}
                  />
                ))

                // : null
              }

              <Box sx={{ mb: 2 }}>
                {TopSections.map((section) => (
                  <NavSection
                    key={section.title}
                    openSideBarState={openSideBarState}
                    pathname={location.pathname}
                    {...section}
                  />
                ))}
              </Box>

              {userSectionsState.map((section) => (
                <NavSection
                  key={section.title}
                  pathname={location.pathname}
                  openSideBarState={openSideBarState}
                  // @ts-ignore
                  disable={section?.disable}
                  sx={{
                    "& + &": {
                      mt: 3,
                    },
                  }}
                  {...section}
                />
              ))}
            </Box>
          )}
        </Box>
      </Scrollbar>
    </Fragment>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            // backgroundColor: "#171819",
            position: "relative",
            zIndex: 9,
            overflowY: "initial",
            // height: "calc(100% - 64px) !important",
            // top: "80px !Important",
            // width: 280,
          },
        }}
        // sx={{ width: sideBarWidth, transition: "width 0.5s ease-in-out" }}
        sx={{ width: sideBarWidth }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          // backgroundColor: "#171819",
          position: "relative",
          width: 270,
        },
      }}
      variant="temporary"
    >
      <IconButton
        color="inherit"
        // onClick={onSidebarMobileOpen}
        sx={{
          display: {
            lg: "none",
          },
          ml: {
            xs: "-20px",
          },
        }}
      >
        <ReactSVG src="/images/darkLogo.svg" />
        {/* <ReactSVG src="/images/ham.svg" className="sidebar-toggler" /> */}
      </IconButton>
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
