import { gql } from "@apollo/client";

const ADD_RANKING_CATEGORY = gql`
  mutation createRankingTournamentCategory(
    $rankingCategory: Int!
    $tournamentCategory: Int!
  ) {
    createRankingTournamentCategory(
      createRankingTournamentCategoryInput: {
        rankingCategory: $rankingCategory
        tournamentCategory: $tournamentCategory
      }
    ) {
      id
    }
  }
`;

export default ADD_RANKING_CATEGORY;
