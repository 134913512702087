import { gql } from "@apollo/client";

const DICIPLINE_BY_ID = gql`
  query discipline($id: Int!) {
    discipline(id: $id) {
      id
      title
      name
      resultType
      icon
      sport {
        id
      }
    }
  }
`;

export default DICIPLINE_BY_ID;
