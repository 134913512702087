import { useEffect, useState } from "react";
import type { FC } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Breadcrumbs, Grid, Link } from "@material-ui/core";
import gtm from "src/lib/gtm";
import { useQuery } from "@apollo/client";
import { DISCIPLINES, SPORTS } from "src/graphql/queries";
import { useNavigate } from "react-router-dom";
import NotFoundComp from "src/components/dashboard/NotFound";
import { DisciplineTable } from "src/components/dashboard/disciplines";
import Loading from "src/components/Loading";
import useAuth from "src/hooks/useAuth";
import { useTranslation } from "react-i18next";
import useFederationRedirect from "src/utils/redirectTodashboard";

const Disciplines: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [disciplineData, setDisciplineData] = useState<any>([]);
  const auth = useAuth();
  const { checkAndRedirect } = useFederationRedirect();
  const { platformId } = useAuth();
  const { loading, data, refetch } = useQuery(DISCIPLINES, {
    variables: {
      filter: {
        ...(location.pathname === "/dashboard/platform-disciplines" && {
          platform: platformId,
        }),
      },
    },
    onCompleted: (data) => {
      setDisciplineData(data?.disciplines);
    },
  });

  useEffect(() => {
    refetch();
  }, [platformId]);

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);
  useEffect(() => {
    checkAndRedirect(auth?.platformId, auth?.user);
  }, [auth?.platformId]);

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <Helmet>
        <title>Dashboard: Disciplines</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 4,
        }}
      >
        <Grid container justifyContent="space-between" spacing={0}>
          <Grid item>
            <Breadcrumbs aria-label="breadcrumb" separator={"/"}>
              <Link
                color="textPrimary"
                component={RouterLink}
                to="/dashboard"
                variant="subtitle2"
              >
                {t("Home")}
              </Link>
              <Link
                color="textPrimary"
                component={RouterLink}
                to={
                  location.pathname.includes("/dashboard/platform-disciplines")
                    ? "/dashboard/platform-disciplines"
                    : "/dashboard/disciplines"
                }
                variant="subtitle2"
              >
                {t("Disciplines")}
              </Link>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Box sx={{ mt: 1 }}>
          {disciplineData?.length > 0 ? (
            <DisciplineTable tour={disciplineData} />
          ) : (
            <>
              <Box sx={{ mt: 4 }}>
                <NotFoundComp
                  link={() => navigate("/dashboard/disciplines/new")}
                  btnTitle={
                    auth?.user.role !== "federation" && t("ADD NEW DISCIPLINE")
                  }
                  noText={t("There is no available disciplines")}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Disciplines;
