import { gql } from "@apollo/client";

const GET_CLUBS = gql`
  query clubs($filter: ListClubInput!) {
    clubs(filter: $filter) {
      club {
        clubName
        id
        country
        logo
        city
        address
        status
        clubServices {
          id
        }
      }
    }
  }
`;

export default GET_CLUBS;
