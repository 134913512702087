import { gql } from "@apollo/client";

const LEAGUE_MEMBER_ROLES = gql`
  query leagueRoles {
    leagueRoles {
      id
      name
    }
  }
`;

export default LEAGUE_MEMBER_ROLES;
