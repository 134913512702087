import { gql } from "@apollo/client";

const GET_TEMPLATE = gql`
  query {
    templates {
      id
      template
    }
  }
`;

export default GET_TEMPLATE;
