import { gql } from "@apollo/client";

const UPDATE_LICENSE = gql`
  mutation updateLicense(
    $id: Int!
    $title: String!
    $fee: Float!
    $currency: String!
    $duration: String!
    $status: String!
    $startDate: DateTime!
    $endDate: DateTime!
    $tournatedLicenseFee: Float
    $partnerLicenseFee: Float
  ) {
    updateLicense(
      updateLicenseInput: {
        id: $id
        title: $title
        fee: $fee
        currency: $currency
        duration: $duration
        status: $status
        startDate: $startDate
        endDate: $endDate
        tournatedLicenseFee: $tournatedLicenseFee
        partnerLicenseFee: $partnerLicenseFee
      }
    ) {
      id
      title
      fee
      currency
      duration
      status
      startDate
      endDate
    }
  }
`;

export default UPDATE_LICENSE;
