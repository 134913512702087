import { gql } from "@apollo/client";

const ADD_NEWS_LEAGUE = gql`
  mutation createLeagueNews($league: Int!, $news: Int!) {
    createLeagueNews(createLeagueNewsInput: { league: $league, news: $news }) {
      id
    }
  }
`;

export default ADD_NEWS_LEAGUE;
