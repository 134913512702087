import { gql } from "@apollo/client";

const UPDATE_DISCIPLINE = gql`
  mutation updateDiscipline(
    $id: Int!
    $icon: Upload
    $title: String!
    $resultType: String
    $sport: Int
  ) {
    updateDiscipline(
      updateDisciplineInput: {
        id: $id
        icon: $icon
        title: $title
        resultType: $resultType
        sport: $sport
      }
    ) {
      id
      icon
      title
      resultType
    }
  }
`;

export default UPDATE_DISCIPLINE;
