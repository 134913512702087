import { gql } from "@apollo/client";

const COPY_RANKING_CATEGORIES = gql`
  mutation copyLeagueCategory(
    $catIds: [Int!]!
    $leagueId: Int!
  ) {
    copyLeagueCategory(
      catIds: $catIds
      leagueId: $leagueId
    ) {
      id
    }
  }
`;

export default COPY_RANKING_CATEGORIES;
