import { gql } from "@apollo/client";

const UPDATE_PLATFORM_DOCUMENT_POSITION = gql`
  mutation updatePlatformDocumentSorting($platformDocuments: [JSONObject!]) {
    updatePlatformDocumentSorting(
      updatePlatformDocumentInput: { platformDocuments: $platformDocuments }
    ) {
      id
    }
  }
`;

export default UPDATE_PLATFORM_DOCUMENT_POSITION;
