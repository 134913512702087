import { gql } from "@apollo/client";

const LEAGUE_MEMBER = gql`
  query leagueMembers($memberId: Int!) {
    leagueMembers(memberId: $memberId) {
      id
      league {
        id
        title
        status
      }
      role {
        id
        role {
          id
          name
        }
      }
    }
  }
`;

export default LEAGUE_MEMBER;
