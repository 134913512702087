import { gql } from "@apollo/client";

const ADD_NEW_CATEGORY_RULE = gql`
  mutation createCategoryRule(
    $category: Int!
    $sector: String
    $numberOfTournaments: Int
    $tournamentCategories: [Int!]
    $licenseSubtypes: String
    $thirdPartyLicense: Int
    $coefficient: Float
  ) {
    createCategoryRule(
      createCategoryRuleInput: {
        category: $category
        sector: $sector
        numberOfTournaments: $numberOfTournaments
        licenseSubtypes: $licenseSubtypes
        tournamentCategories: $tournamentCategories
        thirdPartyLicense: $thirdPartyLicense
        coefficient: $coefficient
      }
    ) {
      id
    }
  }
`;
export default ADD_NEW_CATEGORY_RULE;
