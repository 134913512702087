import { gql } from "@apollo/client";

const CREATE_LEAGUE = gql`
  mutation createLeagueInput(
    $title: String!
    $type: String!
    $abbreviation: String!
    $priority: String!
    $sports: Int
    $discipline: String
    $enableRankings: Boolean
    $enableLicense: Boolean
    $enableOnlinePayments: Boolean
    $status: String
    $email: String
    $website: String
    $phone: String
    $instagram: String
    $federation: Float!
    $platform: Int!
    $abbreviationLogo: Upload
    $file: Upload
    $country: String
    $isFeatured: Boolean
    $createFactSheetInput: [CreateFactSheetInput!]!
  ) {
    createLeague(
      createLeagueInput: {
        title: $title
        type: $type
        abbreviation: $abbreviation
        federation: $federation
        priority: $priority
        sports: $sports
        discipline: $discipline
        enableRankings: $enableRankings
        enableLicense: $enableLicense
        enableOnlinePayments: $enableOnlinePayments
        status: $status
        email: $email
        website: $website
        phone: $phone
        instagram: $instagram
        abbreviationLogo: $abbreviationLogo
        logo: $file
        platform: $platform
        country: $country
        isFeatured: $isFeatured
      }
      createFactSheetInput: $createFactSheetInput
    ) {
      title
      type
      status
      email
      website
      id
    }
  }
`;

export default CREATE_LEAGUE;
