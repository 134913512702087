import { gql } from "@apollo/client";

const PLATFORM_ATHLETES = gql`
  query ($filter: ListTournamentTeamMembersInput, $page: Int, $limit: Int, $platform: Int) # $gender: String, $nation: [String!], $sports: [Int!], $clubs: [Int!], $ageFrom: String, $ageTo: String
  {
    findPlatformAthletes(page: $page, limit: $limit, filter: $filter, platform: $platform) # nation: $nation, sports: $sports, clubs: $clubs, gender: $gender, ageFrom: $ageFrom, ageTo: $ageTo
    {
      tournamentTeamMembers {
        user {
          id
          email
          name
          surname
          avatar
          nation
          phone
          city
          ipinId
          duprId
          teId
          ltsU10Id
          atpId
          wtaId
          fideId
          role
          additionalUserRole
        }
      }
      total
    }
  }
`;

export default PLATFORM_ATHLETES;
