import { gql } from "@apollo/client";

const REMOVE_CLUB_MEMBER = gql`
  mutation removeClubMember($id: Int!) {
    removeClubMember(id: $id) {
      club {
        id
      }
    }
  }
`;

export default REMOVE_CLUB_MEMBER;
