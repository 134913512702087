import { gql } from "@apollo/client";

const UPDATE_TOUR_CAT_POSITION = gql`
  mutation updateTournamentCategorySorting(
    $tournamentCategories: [JSONObject!]!
  ) {
    updateTournamentCategorySorting(
      updateTournamentCategoryInput: {
        tournamentCategories: $tournamentCategories
      }
    ) {
      id
    }
  }
`;

export default UPDATE_TOUR_CAT_POSITION;
