import { gql } from "@apollo/client";

const DELETE_RANKING_CATEGORY = gql`
  mutation removeRankingTournamentCategory($id: Int!) {
    removeRankingTournamentCategory(id: $id) {
      createdAt
    }
  }
`;

export default DELETE_RANKING_CATEGORY;
