import { gql } from "@apollo/client";

const UPDATE_RANKING_POINTS = gql`
  mutation updateSimpleRankingPoint(
    $id: Int!
    $place: Int!
    $entries: Int!
    $name: String!
  ) {
    updateSimpleRankingPoint(
      updateRankingPointInput: {
        id: $id
        place: $place
        entries: $entries
        name: $name
      }
    ) {
      id
      name
    }
  }
`;

export default UPDATE_RANKING_POINTS;
