import { gql } from "@apollo/client";

const ADD_ATHLETE = gql`
  mutation createAthlete($league: Int!, $athlete: Int!) {
    createAthlete(createAthleteInput: { league: $league, athlete: $athlete }) {
      id
    }
  }
`;

export default ADD_ATHLETE;
