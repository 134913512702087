import { gql } from "@apollo/client";

const LEAGUE_RANKINGS_DATES = gql`
  query leagueRankings($filter: ListLeagueRankingInput!) {
    leagueRankings(filter: $filter) {
      id
      createdAt
      date
      updatedAt
      rankingCategory {
        id
        category {
          id
          name
        }
      }
    }
  }
`;

export default LEAGUE_RANKINGS_DATES;
