import { gql } from "@apollo/client";

const ADD_MEMBER = gql`
  mutation addMember(
    $id: Int!
    $members: [String!]
    $role: [Int!]
    # $leagues: [Int!]
    $organizationName: String
    $organizationEmail: String
    $isOrganization: Boolean
    $organizationPhone: String
    $organizationLogo: Upload
    $displayMember: Boolean
  ) {
    addMember(
      updateLeagueInput: {
        id: $id
        members: $members
        role: $role
        #leagues: $leagues
        organizationName: $organizationName
        organizationEmail: $organizationEmail
        isOrganization: $isOrganization
        organizationPhone: $organizationPhone
        organizationLogo: $organizationLogo
        displayMember: $displayMember
      }
    ) {
      id
    }
  }
`;

export default ADD_MEMBER;
