import { gql } from "@apollo/client";

const UPDATE_RANKING_RULE = gql`
  mutation updateRankingPointRule(
    $id: Int!
    $coefficient: Float!
    $sector: String!
    $numberOfPlayers: String
    $operator: String
    $skillLevel: String
    $type: String
    $numberOfEntries: String
  ) {
    updateRankingPointRule(
      updateRankingPointRuleInput: {
        id: $id
        coefficient: $coefficient
        sector: $sector
        numberOfPlayers: $numberOfPlayers
        operator: $operator
        skillLevel: $skillLevel
        type: $type
        numberOfEntries: $numberOfEntries
      }
    ) {
      id
    }
  }
`;

export default UPDATE_RANKING_RULE;
