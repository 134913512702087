import { gql } from "@apollo/client";

const CREATE_ATHLETE = gql`
  mutation createAthlete(
    $league: Int!
    $name: String!
    $surname: String!
    $email: String!
    $phone: String
    $nation: String!
    $gender: String!
    $city: String
    $user_address: String
    $user_join_club: String
    $national_fisher_id: String!
    #$user_coach: String!
    $role: String!
    $password: String!
    $dob: String!
    $avatar: Upload
  ) {
    createAthlete(
      createAthleteInput: {
        league: $league
        name: $name
        surname: $surname
        email: $email
        phone: $phone
        nation: $nation
        gender: $gender
        city: $city
        user_address: $user_address
        user_join_club: $user_join_club
        national_fisher_id: $national_fisher_id
        #user_coach: $user_coach
        role: $role
        password: $password
        avatar: $avatar
        dob: $dob
      }
    ) {
      id
    }
  }
`;

export default CREATE_ATHLETE;
