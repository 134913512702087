import { gql } from "@apollo/client";

const GET_PLATFORM_BY_ID = gql`
  query platform($id: Int!) {
    platform(id: $id) {
      id
      club {
        id
        clubName
        status
        logo
        city
        country
        status
      }
      sports {
        id
        sport {
          id
          title
        }
      }
      thirdPartyLicenses {
        id
        title
      }
    }
  }
`;

export default GET_PLATFORM_BY_ID;
