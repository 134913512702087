import { gql } from "@apollo/client";

const SEARCH_TOURNAMENTS = gql`
  query searchTournamentsByTitle($title: String!) {
    searchTournamentsByTitle(title: $title) {
      id
      title
      address
      startDate
      endDate
      country
      city
      coverPhoto
      withdrawlDeadline
      type
      status
      additionalQuestion {
        id
        question
      }
      tournamentCategory {
        id
        category {
          name
        }
        fee
      }
      organizer {
        user {
          name
        }
      }
      referee {
        referee {
          name
        }
      }
      additionalReferee {
        referee {
          name
        }
      }
    }
  }
`;

export default SEARCH_TOURNAMENTS;
