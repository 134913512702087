import { gql } from "@apollo/client";

const ADD_NEWS_CLUB = gql`
  mutation createClubNews($club: Int!, $news: Int!) {
    createClubNews(createClubNewsInput: { club: $club, news: $news }) {
      id
    }
  }
`;

export default ADD_NEWS_CLUB;
