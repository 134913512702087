import { gql } from "@apollo/client";

const ADD_CLUB_ROLE = gql`
  mutation createClubsService($club: Int!, $services: Int!) {
    createClubsService(
      createClubsServiceInput: { club: $club, services: $services }
    ) {
      id
    }
  }
`;

export default ADD_CLUB_ROLE;
