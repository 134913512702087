import { gql } from "@apollo/client";

const UPDATE_LEAGUE_RANKING = gql`
  mutation updateLeagueRankingPoints(
    $league: Int!
    $rankingCategories: [Int!]
    $createdAt: DateTime
  ) {
    updateLeagueRankingPoints(
      updateLeagueRankingInput: {
        league: $league
        rankingCategories: $rankingCategories
        createdAt: $createdAt
      }
    ) {
      id
    }
  }
`;

export default UPDATE_LEAGUE_RANKING;
