import { gql } from "@apollo/client";

const LEAGUE_BY_ID = gql`
  query ($id: ID!, $gender: String) {
    league(id: $id, gender: $gender) {
      id
      title
      country
      type
      enableRankings
      enableLicense
      enableOnlinePayments
      isFeatured
      status
      isDefault
      platform {
        id
      }
      sports {
        id
        title
        resourceTitle
        sportType
        discipline {
          id
          title
        }
      }

      email
      website
      phone
      instagram
      abbreviation
      priority
      license
      discipline
      logo
      abbreviationLogo

      factSheet {
        id
        fieldTitle
        fieldType
        options
      }
      members {
        member {
          email
        }
      }
      owner {
        federation {
          id
        }
      }
      leagueCategory {
        category {
          id
          name
          type
          gender
          uniqueCode
          maxTournaments
          activeFrom
          activeTo
          sort
          ageLimit
          ageLimitType
          selectStage
          countries
        }
      }
    }
  }
`;

export default LEAGUE_BY_ID;
