import { gql } from "@apollo/client";

const REMOVE_RANKING_CATEGORY = gql`
  mutation removeRankingPointCategory($id: Int!) {
    removeRankingPointCategory(id: $id) {
      id
    }
  }
`;

export default REMOVE_RANKING_CATEGORY;
