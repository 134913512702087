import { gql } from "@apollo/client";

const GET_DISPLAY_SETTING = gql`
  query displaySettings {
    displaySettings {
      id
      isDisplaySeeds
      isDisplaySegments
      isDisplayCity
      isDisplayClub
      isDisplayAthleteChessTitle
      sport {
        id
        title
      }
    }
  }
`;

export default GET_DISPLAY_SETTING;
