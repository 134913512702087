import { gql } from "@apollo/client";

const REMOVE_LEAGUE_RANKING = gql`
  mutation removeLeagueRankings($league: Int!, $rankingCategory: Int!, $date: String!) {
    removeLeagueRankings(league: $league, rankingCategory: $rankingCategory, date: $date) {
      id
    }
  }
`;

export default REMOVE_LEAGUE_RANKING;
