import { gql } from "@apollo/client";

const CLUBS = gql`
  query CLUBS($page: Int, $limit: Int, $sport: String, $filter: ListClubInput) {
    clubs(page: $page, limit: $limit, sport: $sport, filter: $filter) {
      club {
        id
        clubName
        city
        country
        logo
        status
        tournaments {
          id
        }
        clubServices {
          id
          services {
            sport {
              id
              title
              icon
            }
          }
        }
        federation {
          id
        }
        members {
          id
          user {
            id
            name
            email
          }
          userRole
        }
      }
      total
    }
  }
`;

export default CLUBS;
