import { gql } from "@apollo/client";

const ADD_CLUB_MEMBER = gql`
  mutation createClubMember($club: Int!, $user: Int!, $userRole: String) {
    createClubMember(
      createClubMemberInput: { club: $club, user: $user, userRole: $userRole }
    ) {
      id
    }
  }
`;
export default ADD_CLUB_MEMBER;
