import { gql } from "@apollo/client";

const UPDATE_ATHLETE = gql`
  mutation updateAthlete(
    $id: Int!
    $name: String!
    $surname: String!
    $phone: String
    $nation: String!
    $gender: String!
    $city: String
    $user_address: String
    $user_join_club: String
    $national_fisher_id: String!
    #$user_coach: String!
    $role: String!
    $dob: String!
    $avatar: Upload
  ) {
    updateAthlete(
      updateAthleteInput: {
        id: $id
        name: $name
        surname: $surname
        phone: $phone
        nation: $nation
        gender: $gender
        city: $city
        user_address: $user_address
        user_join_club: $user_join_club
        national_fisher_id: $national_fisher_id
        #user_coach: $user_coach
        role: $role
        dob: $dob
        avatar: $avatar
      }
    ) {
      id
    }
  }
`;

export default UPDATE_ATHLETE;
