import { gql } from "@apollo/client";

const UPDATE_DOCUMENT = gql`
  mutation updatePlatformDocument(
    $id: Int!
    $name: String
    $isLeaguePage: Boolean
    $isTournamentCalender: Boolean
    $isRankings: Boolean
    $document: Upload
  ) {
    updatePlatformDocument(
      updatePlatformDocumentInput: {
        id: $id
        name: $name
        isLeaguePage: $isLeaguePage
        isTournamentCalender: $isTournamentCalender
        isRankings: $isRankings
        document: $document
      }
    ) {
      id
    }
  }
`;

export default UPDATE_DOCUMENT;
