import { gql } from "@apollo/client";

const DELETE_ROLE = gql`
  mutation removeLeagueMemberRole($id: Int!) {
    removeLeagueMemberRole(id: $id) {
      createdAt
    }
  }
`;

export default DELETE_ROLE;
