import { gql } from "@apollo/client";

const RENEW_PURCHASED_LICENSE = gql`
  mutation renewUserLicense(
    $paid: Float!
    $currency: String!
    $user: Int
    $license: Int!
    $startDate: DateTime!
    $endDate: DateTime!
    $type: String!
    $userData: CreateUserInput
    $payment_status: LicensePaymentStatus
  ) {
    renewUserLicense(
      createPurchasedLicenseInput: {
        paid: $paid
        currency: $currency
        user: $user
        license: $license
        startDate: $startDate
        endDate: $endDate
        type: $type
        userData: $userData
        payment_status: $payment_status
      }
    ) {
      id
    }
  }
`;

export default RENEW_PURCHASED_LICENSE;
