import { gql } from "@apollo/client";

const MEMBER_BY_ID = gql`
  query leagueMember($id: Int!) {
    leagueMember(id: $id) {
      id
      member {
        name
        surname
        email
        phone
        nation
        gender
        city
        street
        user_club_text
        role
        password
        dob
        id
        avatar
      }
      role {
        id
        role {
          id
          name
        }
      }
      organizationName
      organizationEmail
      organizationPhone
      organizationLogo
      isOrganization
      displayMember
    }
  }
`;

export default MEMBER_BY_ID;
