import { gql } from "@apollo/client";

const DELETE_CATEGORIES = gql`
  mutation removeTournamentCategory($ids: [Int!]!) {
    removeTournamentCategory(ids: $ids) {
      ageType
    }
  }
`;

export default DELETE_CATEGORIES;
