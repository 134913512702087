import { gql } from "@apollo/client";

const PARTNERS = gql`
  query partners($filter: ListPartnerInput) {
    partners(filter: $filter) {
      id
      title
      link
      gender
      image
      sponsorText
      priority
      tournamentsSum
      activeFrom
      activeTo
      ageRestriction
      licenseRequired
      license
    }
  }
`;

export default PARTNERS;
