import { useEffect, useRef, useState } from "react";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@material-ui/core";
import Cookies from "js-cookie";
import { ReactSVG } from "react-svg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import useAuth from "src/hooks/useAuth";
import GetFlag from "src/utils/getFlag";

const languageOptions = {
  en: { icon: "united kingdom", label: "English", value: "English" },
  la: { icon: "latvia", label: "Latviešu", value: "Latvian" },
  esp: { icon: "spain", label: "Español", value: "Spanish" },
  pt: { icon: "portugal", label: "Português", value: "Portuguese" },
  ja: { icon: "japan", label: "日本語", value: "Japanese" },
  ee: { icon: "estonia", label: "eesti keel", value: "Estonian" },
  fr: { icon: "france", label: "Français", value: "French" },
  pl: { icon: "poland", label: "Polski", value: "Polish (Polski)" },
  ka: { icon: "Georgia", label: "Georgian", value: "Georgian" },
  ru: { icon: "Russia", label: "Русский", value: "Russian" },
  cs: { icon: "czech republic", label: "Čeština", value: "Czech" }, // Czech language added
  hr: { icon: "croatia", label: "Hrvatski", value: "Croatian" },
};

const LanguagePopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const { platformLanguages } = useAuth();
  const [filteredLanguages, setFilterdLanguage] = useState({});
  // let filteredLanguages = {};

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChangeLanguage = (lang: string): void => {
    i18n.changeLanguage(lang);
    Cookies.set("lang", lang);
    setOpen(false);
  };
  useEffect(() => {
    // Filter the language options based on the newLanguage array
    const filtered = Object.keys(languageOptions)
      .filter(
        (key) =>
          platformLanguages &&
          JSON.parse(platformLanguages).includes(languageOptions[key].value)
      )
      .reduce((obj, key) => {
        obj[key] = languageOptions[key];
        return obj;
      }, {});

    setFilterdLanguage(filtered);
    let lang = Cookies.get("lang");
    if (lang) {
      i18n.changeLanguage(lang);
    } else {
      if (filtered) {
        const firstEntry = filtered && Object.entries(filtered)[0];
        if (firstEntry) {
          const [firstKey, firstValue] = firstEntry;
          Cookies.set("lang", firstKey);
          i18n.changeLanguage(firstKey);
        }
      }
    }
  }, [platformLanguages]);
  const selectedOption = filteredLanguages && filteredLanguages[i18n.language];

  return (
    <Box sx={{ marginTop: "-2px" }}>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          "&:hover": { background: "transparent" },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
          paddingTop: 0,
          paddingBottom: 0,
        }}
        // disabled
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // height: 20,
            // width: 20,
            "& img": {
              width: "100%",
            },
          }}
        >
          {/* <ReactSVG src={selectedOption.icon} style={{height:"27px"}}/> */}
          {GetFlag(
            selectedOption?.icon !== "undefined"
              ? selectedOption?.icon
              : "LATVIA",
            {
              width: "18px",
              height: "16px",
              marginTop: "0px",
              marginRight: "0px",
            }
          )}
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "12px",
              color: "#0F2927",
              marginLeft: "10px",
              whiteSpace: "nowrap",
              // marginTop: "2px",
            }}
          >
            {selectedOption?.label}
          </Typography>
          <ArrowDropDownIcon sx={{ ml: 1, color: "#1B3861" }} />
        </Box>
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: 160,
            boxShadow:
              "0px 6px 9px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.16)",
            borderRadius: "8px",
          },
        }}
      >
        {Object.keys(filteredLanguages).map((language) => (
          <MenuItem
            onClick={() => handleChangeLanguage(language)}
            key={language}
          >
            <ListItemIcon>
              {GetFlag(
                filteredLanguages[language]?.icon !== "undefined"
                  ? filteredLanguages[language]?.icon
                  : "LATVIA",
                {
                  width: "15px",
                  height: "15px",
                  marginTop: "5px",
                  marginRight: "0px",
                }
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "12px",
                    color: "#0F2927",
                    // marginTop: "2px",
                  }}
                >
                  {filteredLanguages[language]?.label}
                </Typography>
              }
            />
          </MenuItem>
        ))}
      </Popover>
    </Box>
  );
};

export default LanguagePopover;
