import { gql } from "@apollo/client";

const CREATE_LEAGUE_RANKING = gql`
  mutation createLeagueRanking($league: Int!, $rankingCategories: [Int!], $date: String!) {
    createLeagueRanking(createLeagueRankingInput: { league: $league, rankingCategories: $rankingCategories, date: $date }) {
      id
    }
  }
`;

export default CREATE_LEAGUE_RANKING;
