import { gql } from "@apollo/client";

const NEWS_BY_ID = gql`
  query news($slug: String!) {
    news(slug: $slug) {
      id
      title
      image
      postText
      category
      isDisplayLanding
      platform {
        id
        title
      }
      youtubeVideoLink
      date
      author {
        id
        name
        surname
      }
      clubNews {
        id
        club {
          id
          contactEmail
          clubName
        }
      }
      leagueNews {
        id
        league {
          id
          title
        }
      }
      federationNews {
        id
        federation {
          id
          organizationName
          user {
            id
            email
          }
        }
        news {
          id
          title
        }
      }
    }
  }
`;

export default NEWS_BY_ID;
