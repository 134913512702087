import { gql } from "@apollo/client";

const UPDATE_PLAN = gql`
  mutation updatePlan(
    $id: Int!
    $title: String
    $price: String
    $monthlyDiscount: String
    $yearlyDiscount: String
    $currency: String
    $features: [String!]
  ) {
    updatePlan(
      updatePlanInput: {
        id: $id
        title: $title
        price: $price
        currency: $currency
        monthlyDiscount: $monthlyDiscount
        yearlyDiscount: $yearlyDiscount
        features: $features
      }
    ) {
      id
      title
    }
  }
`;

export default UPDATE_PLAN;
