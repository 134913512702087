import { gql } from "@apollo/client";

const REMOVE_PLATFORM_MEMBER = gql`
  mutation removeOrganizationPlatformMember($id: Int!) {
    removeOrganizationPlatformMember(id: $id) {
      role
    }
  }
`;

export default REMOVE_PLATFORM_MEMBER;
