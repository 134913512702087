import { gql } from "@apollo/client";

const GET_DISPLAY_SETTING_BY_ID = gql`
  query displaySetting($id: Int!) {
    displaySetting(id: $id) {
      isDisplaySeeds
      isDisplaySegments
      isDisplayCity
      isDisplayClub
      isDisplayAthleteChessTitle
      sport {
        id
      }
    }
  }
`;

export default GET_DISPLAY_SETTING_BY_ID;
