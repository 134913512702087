import { gql } from "@apollo/client";

const ADD_USER_SPORT = gql`
  mutation createUserSport($user: Int!, $sport: Int!) {
    createUserSport(createUserSportInput: { user: $user, sport: $sport }) {
      id
    }
  }
`;
export default ADD_USER_SPORT;
