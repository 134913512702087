import { gql } from '@apollo/client';

const UPDATE_RANKINGS_ENTRY = gql`
  mutation updateTournamentRankingsEntry($id: Int!, $entry: Int, $place: String, $points: Int) {
    updateTournamentRankingsEntry(updateTournamentRankingsEntryInput: { id: $id, entry: $entry, place: $place, points: $points }) {
      id
    }
  }
`;

export default UPDATE_RANKINGS_ENTRY;
