import { gql } from "@apollo/client";

const UPDATE_DISPLAY_SETTING = gql`
  mutation updateDisplaySetting(
    $id: Int!
    $sport: Int
    $isDisplaySeeds: Boolean
    $isDisplaySegments: Boolean
    $isDisplayAthleteChessTitle: Boolean
    $isDisplayCity: Boolean
    $isDisplayClub: Boolean
  ) {
    updateDisplaySetting(
      updateDisplaySettingInput: {
        id: $id
        sport: $sport
        isDisplaySeeds: $isDisplaySeeds
        isDisplaySegments: $isDisplaySegments
        isDisplayAthleteChessTitle: $isDisplayAthleteChessTitle
        isDisplayCity: $isDisplayCity
        isDisplayClub: $isDisplayClub
      }
    ) {
      isDisplaySeeds
    }
  }
`;

export default UPDATE_DISPLAY_SETTING;
