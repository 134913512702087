import { gql } from "@apollo/client";

const DELETE_LICENSE_LEAGUE = gql`
  mutation removeLicenseLeague($id: Int!) {
    removeLicenseLeague(id: $id) {
      createdAt
    }
  }
`;

export default DELETE_LICENSE_LEAGUE;
