import { gql } from "@apollo/client";

const PURCHASED_LICENSES = gql`
  query purchasedLicenses(
    $federation: Int
    $orderBy: String
    $filter: ListPurchasedLicensesInput
  ) {
    purchasedLicenses(
      federation: $federation
      orderBy: $orderBy
      filter: $filter
    ) {
      id
      paid
      startDate
      endDate
      currency
      type
      createdAt
      licenseStatus
      addedBy {
        id
        name
      }
      license {
        id
        title
        status
        duration
      }
      basePrice
      user {
        id
        name
        surname
        phone
        email
      }
    }
  }
`;

export default PURCHASED_LICENSES;
