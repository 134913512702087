import { gql } from "@apollo/client";

const ADD_DOCUMENTS = gql`
  mutation createPlatformDocument(
    $platform: Int
    $isLeaguePage: Boolean
    $isTournamentCalender: Boolean
    $isRankings: Boolean
    $name: String
    $document: Upload
  ) {
    createPlatformDocument(
      createPlatformDocumentInput: {
        platform: $platform
        isLeaguePage: $isLeaguePage
        isTournamentCalender: $isTournamentCalender
        isRankings: $isRankings
        name: $name
        document: $document
      }
    ) {
      id
    }
  }
`;
export default ADD_DOCUMENTS;
