import { gql } from "@apollo/client";

const CREATE_LEAGUE_MEMBER = gql`
  mutation createLeagueMember(
    $member: Int!
    $role: [Int!]
    $leagues: [Int!]
    $organizationName: String
    $organizationEmail: String
    $isOrganization: Boolean
    $organizationPhone: String
    $organizationLogo: Upload
    $displayMember: Boolean
  ) {
    createLeagueMember(
      createLeagueMemberInput: {
        member: $member
        role: $role
        leagues: $leagues
        organizationName: $organizationName
        organizationEmail: $organizationEmail
        isOrganization: $isOrganization
        organizationPhone: $organizationPhone
        organizationLogo: $organizationLogo
        displayMember: $displayMember
      }
    ) {
      id
    }
  }
`;

export default CREATE_LEAGUE_MEMBER;
