import { gql } from "@apollo/client";

const DELETE_CATEGORY_LICENSE = gql`
  mutation removeTournamentCategoryLicense($id: Int!) {
    removeTournamentCategoryLicense(id: $id) {
      license {
        id
      }
    }
  }
`;

export default DELETE_CATEGORY_LICENSE;
