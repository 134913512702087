import { gql } from "@apollo/client";

const ADD_RANKING_POINTS = gql`
  mutation createRankingPoint(
    $league: Int!
    $tournamentCategory: [Int!]
    $place: Int!
    $entries: Int!
    $name: String!
    $points: Float
    $rules: [JSONObject!]
  ) {
    createRankingPoint(
      createRankingPointInput: {
        league: $league
        tournamentCategory: $tournamentCategory
        place: $place
        entries: $entries
        name: $name
        points: $points
        rules: $rules
      }
    ) {
      id
    }
  }
`;

export default ADD_RANKING_POINTS;
