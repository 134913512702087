import { gql } from "@apollo/client";

const RANKING_POINTS = gql`
  query rankingPoints($filter: ListRankingPointsInput) {
    rankingPoints(filter: $filter) {
      id
      name
      place
      entries
      priority
      points
      tournamentCategory {
        id
        tournamentCategory {
          id
          name
        }
      }
      rankingPointRules {
        id
        sector
        coefficient
        type
        numberOfEntries
        operator
        skillLevel
        numberOfPlayers
        tournamentCategory {
          id
          name
        }
      }
      rankingCategoryPoints {
        id
        place
        points
        sortingPosition
        numPlaces
        segment
        bracketType
      }
    }
  }
`;

export default RANKING_POINTS;
