import { gql } from "@apollo/client";

const TOURNAMENTSTATS = gql`
  query stats($federationId: Float, $filter: ListLeagueInput) {
    stats(federationId: $federationId, filter: $filter) {
      allTournaments
      passedTournaments
      upcomingTournaments
      activeTournaments
    }
  }
`;

export default TOURNAMENTSTATS;
