import { Box } from "@material-ui/core";
import data from "../assets/data-loader-orange.json";
import Lottie from "lottie-react";
import { FC } from "react";

interface LoadingProps {
  height?: string;
}

const Loading: FC<LoadingProps> = ({ height }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: height || "100vh",
      }}
    >
      {/* <video autoPlay loop muted style={{ width: "100px", height: "100px" }}>
        <source src="/images/loading-light.webm" type="video/mp4" />
      </video> */}
      <Lottie animationData={data} loop={true} style={{ width: "130px", height: "130px" }} />
    </Box>
  );
};
export default Loading;
