import React, { useState, useEffect } from "react";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { Formik } from "formik";

import { Grid, TextField, Typography, makeStyles } from "@material-ui/core";
import { useLazyQuery } from "@apollo/client";
import { DICIPLINE_BY_ID, DISCIPLINES, SPORTS } from "src/graphql/queries";
import { useMutation } from "@apollo/client";
import { CREATE_DISCIPLINE, UPDATE_DISCIPLINE } from "src/graphql/mutations";
import { useParams } from "react-router-dom";
import SubmitBanner from "src/components/dashboard/submitBanner";
import { handlePreview } from "src/utils/imagePreview";
import UploadFiles from "../uploadFile";
import useAuth from "src/hooks/useAuth";
import { useTranslation } from "react-i18next";

const resultType = [
  {
    title: "Custom metrics",
  },
  {
    title: "Location & Weight fixed",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "32px",
    maxWidth: "1028px",
    border: "1px solid #EDF1F6",
    borderRadius: "8px",
    marginBottom: "40px",
    padding: theme.spacing(4),
  },
  subHeading: {
    fontWeight: 500,
  },
  mainHeading: {
    color: "#0A2540",
    fontSize: "18px",
    fontWeight: 700,
  },
  textfield: {
    background: "#FBFBFB",
    width: "100%",
    marginTop: 8,
  },
  button: {
    background:
      "linear-gradient(100deg, #FF5733 0.25%, #FD4C26 46.18%, #FA8D76 97.15%)",
  },
}));

const DisciplineForm: FC<any> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();
  const auth = useAuth();
  const [getDiscipline] = useLazyQuery(DICIPLINE_BY_ID);
  const navigate = useNavigate();
  const [isFound, setIsFound] = useState(id ? true : false);

  const [logo, setLogo] = useState<any[]>([]);
  const [data, setData] = useState<any>("");
  const [sportType, setSportType] = useState<any>();
  const [selectedSport, setSelectedSport] = useState<any>("");

  const [update, { loading: loader }] = useMutation(UPDATE_DISCIPLINE, {
    refetchQueries: [
      {
        query: DISCIPLINES,
      },
    ],
    onCompleted: () => {
      navigate("/dashboard/disciplines");
    },
  });

  const [createDiscipline, { loading }] = useMutation(CREATE_DISCIPLINE, {
    refetchQueries: [
      {
        query: DISCIPLINES,
      },
    ],
    onCompleted: () => {
      navigate("/dashboard/disciplines");
    },
  });
  const [SportsFunction, { data: Sports }] = useLazyQuery(SPORTS);
  useEffect(() => {
    SportsFunction({
      variables: {
        filter: {
          enableDiscipline: true,
        },
      },
    });
  }, []);

  const DisciplineById = async (id: string) => {
    const variables = {
      id: parseInt(id),
    };
    try {
      const { data } = await getDiscipline({ variables });
      console.log("data?.discipline", data?.discipline);
      setData(data?.discipline);
      setSelectedSport(data?.discipline?.sport?.id);
      await handlePreview(data?.discipline?.icon, setLogo);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (id) {
      DisciplineById(id);
    }
  }, [id]);
  useEffect(() => {
    if (Sports?.sports?.length > 0 && selectedSport) {
      setSportType(
        Sports?.sports?.find((item) => item?.id === parseInt(selectedSport))
      );
    }
  }, [Sports, selectedSport]);

  return (
    <Formik
      enableReinitialize={id ? true : false}
      initialValues={{
        title: data?.title ?? "",
        resultType: data?.resultType ?? "",
        icon: logo ?? [],
        sport: data?.sport?.id ?? "",
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().max(255).required(t("title is required")),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        if (id) {
          let variables = {
            ...values,
            id: parseInt(id),
            icon: values?.icon[0],
            sport: parseInt(values?.sport),
          };
          try {
            await update({ variables });
            toast.success(t("Discipline Updated"));
          } catch (error) {
            toast.error(error.message);
          }
        } else {
          let variables = {
            ...values,
            icon: values?.icon[0],
            sport: parseInt(values?.sport),
            platform: auth?.platformId,
          };
          try {
            await createDiscipline({ variables });
            toast.success(t("Discipline Created"));
          } catch (error) {
            toast.error(error.message);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form onSubmit={handleSubmit} {...props}>
          <SubmitBanner
            isSubmitting={isSubmitting}
            title={t("Add New Discipline")}
            id={id}
            path="/dashboard/disciplines"
            SecText={t("Edit Discipline")}
            isFound={isFound}
            loading={loading}
            loader={loader}
            // handleAdd={handleAddMember}
          />

          <Grid container xs={12} md={12} lg={12}>
            <Grid item md={8} xs={12}>
              <Grid item container xs={12} spacing={3}>
                <Grid item md={6} xs={12}>
                  <Typography className={classes.subHeading} variant="body1">
                    {t("Discipline title")}
                  </Typography>
                  <TextField
                    error={Boolean(touched?.title && errors?.title)}
                    fullWidth
                    helperText={touched?.title && (errors?.title as any)}
                    placeholder={t("Enter Title")}
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.title}
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        border: Boolean(touched?.title && errors?.title)
                          ? "1px solid red"
                          : "1px solid #EFEFF0",
                        "& fieldset": {
                          borderWidth: "0px",
                        },
                        "&:hover fieldset": {
                          borderWidth: "0px",
                          transform: "none",
                        },
                        "&.Mui-focused fieldset": {
                          borderWidth: "0px",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "9px 20px",
                        paddingRight: "0px!important",
                      },
                    }}
                    className={`field_error ${classes.textfield}`}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography className={classes.subHeading} variant="body1">
                    {t("Select Sport")}
                  </Typography>
                  <TextField
                    fullWidth
                    name="sport"
                    value={values?.sport}
                    onChange={(event) => {
                      handleChange(event);
                      setSelectedSport(event.target.value);
                    }}
                    select
                    SelectProps={{ native: true }}
                    variant="outlined"
                    sx={{
                      mt: 1,
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      borderRadius: "6px",
                      border: "1px solid #EFEFF0",
                      background: "#FBFBFB",
                      "& fieldset": {
                        borderWidth: "0px",
                      },
                      "&:hover fieldset": {
                        borderWidth: "0px",
                      },
                      "&.Mui-focused fieldset": {
                        borderWidth: "0px",
                      },
                      "& .MuiOutlinedInput-input": {
                        padding: "12.5px 15px",
                        paddingRight: "0px!important",
                      },
                    }}
                  >
                    <option value="">select sport</option>
                    {Sports?.sports?.length > 0 ? (
                      Sports?.sports?.map((item) => (
                        <option key={item.id} value={item?.id}>
                          {item?.title}
                        </option>
                      ))
                    ) : (
                      <option disabled>{t("No sports found")}</option>
                    )}
                  </TextField>
                </Grid>

                {values?.sport && sportType?.displayListResults ? (
                  <Grid item xs={12}>
                    <Typography className={classes.subHeading} variant="body1">
                      {t("Results type")}
                    </Typography>
                    <TextField
                      fullWidth
                      name="resultType"
                      value={values?.resultType}
                      onChange={handleChange}
                      select
                      SelectProps={{ native: true }}
                      variant="outlined"
                      sx={{
                        mt: 1,
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        borderRadius: "6px",
                        border: "1px solid #EFEFF0",
                        background: "#FBFBFB",
                        "& fieldset": {
                          borderWidth: "0px",
                        },
                        "&:hover fieldset": {
                          borderWidth: "0px",
                        },
                        "&.Mui-focused fieldset": {
                          borderWidth: "0px",
                        },
                        "& .MuiOutlinedInput-input": {
                          padding: "12.5px 15px",
                          paddingRight: "0px!important",
                        },
                      }}
                    >
                      <option value="">select result type</option>
                      {resultType?.length > 0 ? (
                        resultType?.map((item) => (
                          <option key={item.title} value={item.title}>
                            {item.title}
                          </option>
                        ))
                      ) : (
                        <option disabled>{t("No sports found")}</option>
                      )}
                    </TextField>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>

              <Typography
                className={classes.subHeading}
                sx={{ mt: 2, mb: 1 }}
                variant="body1"
              >
                {t("Icon")}
              </Typography>
              <UploadFiles
                maxFiles={1}
                accept="image/*"
                files={values?.icon}
                onDrop={(file) => setFieldValue("icon", file)}
                onRemove={(file) => setFieldValue("icon", [])}
                onRemoveAll={() => setFieldValue("icon", [])}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default DisciplineForm;
