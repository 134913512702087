import { gql } from "@apollo/client";

const CREATE_PARTNER = gql`
  mutation createPartner(
    $title: String!
    $link: String!
    $sponsorText: String!
    $priority: String!
    $activeFrom: DateTime!
    $activeTo: DateTime!
    $file: Upload
    $league: Int
    $federation: Int
    $club: Int
    $platform: Int
  ) {
    createPartner(
      createPartnerInput: {
        title: $title
        link: $link
        sponsorText: $sponsorText
        priority: $priority
        activeFrom: $activeFrom
        activeTo: $activeTo
        image: $file
        league: $league
        federation: $federation
        club: $club
        platform: $platform
      }
    ) {
      id
      title
      link
      image
      sponsorText
      priority
      activeFrom
      activeTo
    }
  }
`;

export default CREATE_PARTNER;
