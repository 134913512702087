import { gql } from "@apollo/client";

const CREATE_DISCIPLINE = gql`
  mutation createDiscipline(
    $title: String!
    $icon: Upload
    $resultType: String
    $sport: Int
    $platform: Int!
  ) {
    createDiscipline(
      createDisciplineInput: {
        title: $title
        icon: $icon
        resultType: $resultType
        sport: $sport
        platform: $platform
      }
    ) {
      title
    }
  }
`;

export default CREATE_DISCIPLINE;
