import { gql } from "@apollo/client";

const ALLTOURNAMENTS = gql`
  query allTournaments($filter: ListTournamentInput) {
    allTournaments(page: 1, limit: 100, filter: $filter) {
      id
      title
      address
      startDate
      endDate
      country
      city
      coverPhoto
      withdrawlDeadline
      type
      status
      additionalQuestion {
        id
        question
      }
      tournamentCategory {
        id
        category {
          name
        }
        fee
      }
      organizer {
        user {
          name
        }
      }
      referee {
        referee {
          name
        }
      }
      additionalReferee {
        referee {
          name
        }
      }
    }
  }
`;

export default ALLTOURNAMENTS;
