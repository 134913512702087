import { gql } from "@apollo/client";

const UPDATE_LEAGUE_MEMBER = gql`
  mutation updateLeagueMember(
    $id: Int!
    $organizationName: String
    $organizationEmail: String
    $isOrganization: Boolean
    $displayMember: Boolean
    $organizationPhone: String
    $organizationLogo: Upload
  ) {
    updateLeagueMember(
      updateLeagueMemberInput: {
        id: $id
        organizationName: $organizationName
        organizationEmail: $organizationEmail
        isOrganization: $isOrganization
        displayMember: $displayMember
        organizationPhone: $organizationPhone
        organizationLogo: $organizationLogo
      }
    ) {
      id
    }
  }
`;

export default UPDATE_LEAGUE_MEMBER;
