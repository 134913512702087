import { gql } from "@apollo/client";

const COPY_RANKING_POINTS_LEAGUE = gql`
  mutation copyRankingPoint(
    $league: Int
    $rankingPoints: [JSONObject!]
    $tournamentCategory: [Int!]
  ) {
    copyRankingPoint(
        createRankingPointInput: {
        league: $league
        rankingPoints: $rankingPoints
        tournamentCategory:$tournamentCategory
      }
    ) {
      id
    }
  }
`;

export default COPY_RANKING_POINTS_LEAGUE;
