import { gql } from "@apollo/client";

const REMOVE_PLATFORM_DOCUMENT = gql`
  mutation removePlatformDocument($id: Int!) {
    removePlatformDocument(id: $id) {
      id
    }
  }
`;

export default REMOVE_PLATFORM_DOCUMENT;
