import { gql } from "@apollo/client";

const ROLES = gql`
  query {
    userRoles {
      id
      name
    }
  }
`;

export default ROLES;
