import { useNavigate } from "react-router-dom";

const useFederationRedirect = () => {
  const navigate = useNavigate();

  const checkAndRedirect = (platformId, user) => {
    const tournatedPlatformId = parseInt(
      process.env.REACT_APP_TOURNATED_PLATFORM_ID
    );

    if (platformId === tournatedPlatformId && user?.role === "federation") {
      navigate("/dashboard");
    }
  };

  return { checkAndRedirect };
};

export default useFederationRedirect;
