import { gql } from "@apollo/client";

const CREATE_TOURNAMENT = gql`
  mutation createTournament(
    $league: Float!
    $title: String!
    $startDate: DateTime!
    $endDate: DateTime!
    $country: String!
    $city: String!
    $address: String!
    $club: String
    $entryFee: String!
    $organizer: Float!
    $referee: Float
    $additionalReferee: Float
    $registerLink: String
    $description: String!
    $entryDeadline: DateTime!
    $timeZone: String!
    $termsAndConditions: String!
    $tournamentCategory: [JSONObject!]
    $file: Upload
    $regulations: Upload
    $courts: Float
    $website: String
    $registeredUsersInTournament: Boolean!
    $closeRegistration: Boolean!
    $approvedEntries: Boolean!
    $partnerSearch: Boolean!
    $loggedInUser: Boolean!
    $withdrawlDeadline: DateTime!
    $additionalQuestion: [JSONObject!]
    $currency: String!
    $type: String!
    $status: String!
  ) {
    createTournament(
      createTournamentInput: {
        league: $league
        title: $title
        startDate: $startDate
        description: $description
        entryDeadline: $entryDeadline
        timeZone: $timeZone
        termsAndConditions: $termsAndConditions
        endDate: $endDate
        country: $country
        address: $address
        club: $club
        city: $city
        entryFee: $entryFee
        organizer: $organizer
        additionalReferee: $additionalReferee
        referee: $referee
        registerLink: $registerLink
        tournamentCategory: $tournamentCategory
        coverPhoto: $file
        regulations: $regulations
        courts: $courts
        website: $website
        registeredUsersInTournament: $registeredUsersInTournament
        closeRegistration: $closeRegistration
        approvedEntries: $approvedEntries
        partnerSearch: $partnerSearch
        loggedInUser: $loggedInUser
        withdrawlDeadline: $withdrawlDeadline
        currency: $currency
        additionalQuestion: $additionalQuestion
        type: $type
        status: $status
      }
    ) {
      id
      title
      startDate
      description
      entryDeadline
      timeZone
      termsAndConditions
      endDate
      country
      address
      club
      city
      entryFee
      registerLink
      withdrawlDeadline
      currency
      type
      status
    }
  }
`;

export default CREATE_TOURNAMENT;
