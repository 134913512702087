import { gql } from "@apollo/client";

const UPDATE_PARTNER = gql`
  mutation updatePartner(
    $id: Int!
    $title: String!
    $link: String!
    $sponsorText: String!
    $priority: String!
    $activeFrom: DateTime!
    $activeTo: DateTime!
    $file: Upload
    $club: Int
    $federation: Int
    $league: Int
    $platform: Int
  ) {
    updatePartner(
      updatePartnerInput: {
        id: $id
        title: $title
        link: $link
        sponsorText: $sponsorText
        priority: $priority
        activeFrom: $activeFrom
        activeTo: $activeTo
        image: $file
        club: $club
        federation: $federation
        league: $league
        platform: $platform
      }
    ) {
      id
      title
      link
      image
      sponsorText
      priority
      activeFrom
      activeTo
    }
  }
`;

export default UPDATE_PARTNER;
