import { gql } from "@apollo/client";

const TEAM_BY_ID = gql`
  query team($id: Int!) {
    team(id: $id) {
      id
      title
      sport {
        id
      }
      club {
        id
      }
      discipline {
        id
      }
      country
      status
      image
      members {
        id
        member {
          name
          id
          email
        }
        team {
          title
        }
        role
      }
    }
  }
`;

export default TEAM_BY_ID;
