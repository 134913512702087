import { gql } from "@apollo/client";

const COPY_CATEGORIES = gql`
  mutation copyTournamentCategories(
    $ids: [Int!]!
    $leagueId: Int!
  ) {
    copyTournamentCategories(
      ids: $ids
      leagueId: $leagueId
    ) {
      id
    }
  }
`;

export default COPY_CATEGORIES;
