import { gql } from "@apollo/client";

const CATEGORIES = gql`
  query {
    categories(page: 1, limit: 10, filter: {}) {
      title
      tour
      id
    }
  }
`;

export default CATEGORIES;
