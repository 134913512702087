import { gql } from "@apollo/client";

const UPDATE_CLUB = gql`
  mutation updateClub(
    $clubName: String!
    $address: String
    $city: String
    $logo: Upload
    $contactEmail: String
    $howToFindUs: String
    $contactPhone: String
    $country: String!
    $instagramLink: String
    $facebookLink: String
    $twitterLink: String
    $tikTok: String
    $linkedin: String
    $website: String
    $id: Int!
    $federation: Int
    $platform: Int
    $clubMainAdministrator: Int
    $isAthleteClub: Boolean
    $status: String
  ) {
    updateClub(
      updateClubInput: {
        clubName: $clubName
        address: $address
        city: $city
        logo: $logo
        howToFindUs: $howToFindUs
        contactEmail: $contactEmail
        contactPhone: $contactPhone
        tikTok: $tikTok
        country: $country
        instagramLink: $instagramLink
        facebookLink: $facebookLink
        twitterLink: $twitterLink
        linkedin: $linkedin
        website: $website
        id: $id
        federation: $federation
        platform: $platform
        isAthleteClub: $isAthleteClub
        clubMainAdministrator: $clubMainAdministrator
        status: $status
      }
    ) {
      id
      country
      logo
      instagramLink
      facebookLink
      twitterLink
      howToFindUs
      contactEmail
      contactPhone
      website
      instagramLink
      facebookLink
      twitterLink
      tikTok
      linkedin
    }
  }
`;

export default UPDATE_CLUB;
