import { gql } from "@apollo/client";

const GET_PLAN_BY_ID = gql`
  query plan($id: Int!) {
    plan(id: $id) {
      id
      title
      features
      currency
      monthlyDiscount
      yearlyDiscount
      price
      planType
    }
  }
`;

export default GET_PLAN_BY_ID;
