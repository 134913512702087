import { gql } from "@apollo/client";

const FORGET = gql`
  mutation forgetPassword(
    $email: String!
    $platformId: Float
    $redirectURL: String
  ) {
    forgetPassword(
      email: $email
      platformId: $platformId
      redirectURL: $redirectURL
    )
  }
`;

export default FORGET;
