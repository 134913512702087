import { gql } from "@apollo/client";

const ME = gql`
  query {
    getProfile {
      email
      name
      surname
      role
      user_sport
      id
    }
  }
`;

export default ME;
