import { gql } from "@apollo/client";

const UPDATE_DISCIPLINE_POSITION = gql`
  mutation updateDisciplineSorting($disciplines: [JSONObject!]) {
    updateDisciplineSorting(
      updateDisciplineInput: { disciplines: $disciplines }
    ) {
      id
    }
  }
`;

export default UPDATE_DISCIPLINE_POSITION;
