import { gql } from "@apollo/client";

const PLATFORM_USERS = gql`
  query (
    $filter: ListLeagueInput
    $page: Int
    $limit: Int
    $gender: String
    $nation: [String!]
    $sports: [Int!]
    $clubs: [Int!]
    $ageFrom: String
    $ageTo: String
  ) {
    platformUsers(
      page: $page
      limit: $limit
      filter: $filter
      nation: $nation
      sports: $sports
      clubs: $clubs
      gender: $gender
      ageFrom: $ageFrom
      ageTo: $ageTo
    )
  }
`;

export default PLATFORM_USERS;
