import { gql } from "@apollo/client";

const FEDERATION_BY_ID = gql`
  query federation($id: Int!) {
    federation(id: $id) {
      user {
        id
        name
        surname
        email
        phone
        instagram
      }
      platforms {
        platform {
          id
          title
          sports {
            sport {
              id
              title
            }
          }
        }
      }
      country
      organizationName
      organizationAbbreviation
      organizationType
      logo
      about
      contactEmail
      contactPhone
      status
      website
      id
    }
  }
`;

export default FEDERATION_BY_ID;
