import { gql } from "@apollo/client";

const UPDATE_FEDERATION = gql`
  mutation updateFederation(
    $federationId: Int!
    $name: String!
    $surname: String!
    $email: String
    $phone: String
    $organizationName: String!
    $organizationAbbreviation: String!
    $organizationType: String!
    $about: String
    $contactEmail: String!
    $contactPhone: String
    $website: String
    $userId: Float
    $logo: Upload
    $country: String
    $status: String
  ) {
    updateFederation(
      updateFederationInput: {
        federationId: $federationId
        name: $name
        surname: $surname
        email: $email
        phone: $phone
        organizationName: $organizationName
        organizationAbbreviation: $organizationAbbreviation
        organizationType: $organizationType
        about: $about
        contactEmail: $contactEmail
        contactPhone: $contactPhone
        website: $website
        userId: $userId
        logo: $logo
        country: $country
        status: $status
      }
    ) {
      user {
        id
        name
        surname
        email
        phone
      }
      logo
      organizationName
      organizationAbbreviation
      organizationType
      about
      contactEmail
      contactPhone
      website
      id
    }
  }
`;

export default UPDATE_FEDERATION;
