import { gql } from "@apollo/client";

const REMOVE_CATEGORY_RULE = gql`
  mutation removeCategoryRule($id: Int!) {
    removeCategoryRule(id: $id) {
      createdAt
    }
  }
`;

export default REMOVE_CATEGORY_RULE;
