import { gql } from "@apollo/client";

const UPDATE_PURCHASED_LICENSE = gql`
  mutation updatePurchasedLicense($id: Int!, $paid: Float!, $currency: String!, $user: Int, $license: Int!, $startDate: DateTime!, $endDate: DateTime!, $type: String!) {
    updatePurchasedLicense(updatePurchasedLicenseInput: { id: $id, paid: $paid, currency: $currency, user: $user, license: $license, startDate: $startDate, endDate: $endDate, type: $type }) {
      id
    }
  }
`;

export default UPDATE_PURCHASED_LICENSE;
