import { useRef, useState } from "react";
import type { FC } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@material-ui/core";
import useAuth from "../../hooks/useAuth";
import CogIcon from "../../icons/Cog";
import UserIcon from "../../icons/User";
import { ReactSVG } from "react-svg";

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
    } catch (err) {
      console.error(err);
      toast.error("Unable to logout.");
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{ display: { lg: "none", xs: "flex" }, alignItems: "center" }}
      >
        {/* <ReactSVG src="/images/ava-small.svg" /> */}
        <ReactSVG src="/images/menu-small.svg" />
      </Box>
      {/* <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          // height: "40px",
          padding: "10px",
          // width: "232px",
          borderRadius: "12px",
          // boxShadow:
          //   "0px 3px 2px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.11)",
          display: { lg: "flex", xs: "none" },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ReactSVG src="/images/avatar.svg" style={{ marginTop: "1px" }} />
          <Typography
            variant="body2"
            sx={{ color: "#193660", ml: "10px", fontWeight: "500" }}
          >
            My Career
          </Typography>
        </Box>
        <ReactSVG src="/images/profile-menu.svg" style={{ marginTop: "1px" }} />
      </Box> */}
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {user?.name}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem
            onClick={() => {
              window.open(process.env.REACT_APP_CLIENT_URL);
            }}
          >
            <ListItemIcon>
              <ReactSVG
                src="/images/athlete.svg"
                style={{
                  paddingLeft: "5px",
                  paddingTop: "5px",
                }}
                beforeInjection={(svg) => {
                  svg.children[0].setAttribute("style", "fill: currentColor;");
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Athlete Portal
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem component={RouterLink} to="/dashboard/social/profile">
            <ListItemIcon>
              <UserIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Profile
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem component={RouterLink} to="/dashboard/account">
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Settings
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
