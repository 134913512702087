import { gql } from "@apollo/client";

const COPY_RANKING_POINTS = gql`
  mutation copyRankingCategoryPoints(
    $rankingPoint: Int
    $rankingCategoryPoints: [JSONObject!]
  ) {
    copyRankingCategoryPoints(
      createRankingCategoryPointInput: {
        rankingCategoryPoints: $rankingCategoryPoints
        rankingPoint: $rankingPoint
      }
    ) {
      id
    }
  }
`;

export default COPY_RANKING_POINTS;
