/**
 * @param {string} fileUrl
 * @param {(files: File[]) => void} setFiles
 * @returns {Promise<File | null>}
 */

export async function handlePreview(
  fileUrl: string,
  setFiles: (files: File[]) => void
): Promise<File | null> {
  try {
    const response = await fetch(fileUrl);
    const blob = await response.blob();

    const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
    const fileType = blob.type;
    const file = new File([blob], fileName, { type: fileType });

    setFiles([file]);
    return file;
  } catch (error) {
    console.error("Error fetching the file:", error);
    return null; // Return null or handle the error as needed
  }
}
