import { gql } from "@apollo/client";

const DELETE_SERVICE = gql`
  mutation removeClubsService($id: Int!) {
    removeClubsService(id: $id) {
      club {
        id
      }
    }
  }
`;

export default DELETE_SERVICE;
