import { gql } from "@apollo/client";

const THIRD_PARTY_LICENSE_BY_SPORTS = gql`
  query thirdPartyLicensesBySportId($filter: ListThirdPartyLicenseInput) {
    thirdPartyLicensesBySportId(filter: $filter) {
      # Specify the fields you want to retrieve for each ThirdPartyLicense
      id
      title
      type
      sports {
        id
        title
        icon
      }
      # Add more fields as needed
    }
  }
`;

export default THIRD_PARTY_LICENSE_BY_SPORTS;
