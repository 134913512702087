import { gql } from "@apollo/client";

const CREATE_THIRD_PARTY_LICENSE = gql`
  mutation createThirdPartyLicense(
    $title: String!
    $type: String!
    $sports: [Int!]
    $organizationName: String!
    $organizationAbbreviation: String
    $endPointLink: String
    $isRequiredInUserProfile: Boolean
    $isRequiredInUserRegistration: Boolean
    $isMakeLicenceMandatory: Boolean
  ) {
    createThirdPartyLicense(
      createThirdPartyLicenseInput: {
        title: $title
        type: $type
        sports: $sports
        organizationName: $organizationName
        organizationAbbreviation: $organizationAbbreviation
        endPointLink: $endPointLink
        isRequiredInUserProfile: $isRequiredInUserProfile
        isRequiredInUserRegistration: $isRequiredInUserRegistration
        isMakeLicenceMandatory: $isMakeLicenceMandatory
      }
    ) {
      title
    }
  }
`;

export default CREATE_THIRD_PARTY_LICENSE;
