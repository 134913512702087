import { gql } from "@apollo/client";

const ORGANIZATION_RANKING_CATEGORIES = gql`
  query leagueCategories(
    $organizationId: Int
    $filter: ListLeagueCategoryInput
  ) {
    leagueCategories(organizationId: $organizationId, filter: $filter) {
      id
      league {
        id
        title
      }
      category {
        id
        name
        type
        gender
      }
    }
  }
`;

export default ORGANIZATION_RANKING_CATEGORIES;
