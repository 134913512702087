import { gql } from "@apollo/client";

const DELETE_NEWS_CLUB = gql`
  mutation removeClubNews($id: Int!) {
    removeClubNews(id: $id) {
      club {
        id
      }
    }
  }
`;

export default DELETE_NEWS_CLUB;
