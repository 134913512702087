import { gql } from "@apollo/client";

const UPDATE_RANKING_CAT_POSITION = gql`
  mutation updateLeagueCategory(
    $leagueCategories: [JSONObject!]!
  ) {
    updateLeagueCategory(
      updateLeagueCategoryInput: {
        leagueCategories: $leagueCategories
      }
    ) {
      id
    }
  }
`;

export default UPDATE_RANKING_CAT_POSITION;
