import { Fragment, useMemo, useEffect, useState, ReactNode, FC } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
} from "@material-ui/core";
import type { ListProps } from "@material-ui/core";
import { ReactSVG } from "react-svg";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import SidebarSubItems from "./SidebarSubItems";
import { useTranslation } from "react-i18next";

interface Item {
  path: string;
  title: string;
}

interface NavSectionProps extends ListProps {
  subItems: Item[];
  pathname: string;
  title: string;
  icon?: string;
  openSideBarState: boolean;
  disable?: boolean;
}

const NavSection: FC<NavSectionProps> = (props) => {
  const {
    subItems,
    pathname,
    title,
    openSideBarState,
    icon,
    disable,
    ...other
  } = props;
  const { t } = useTranslation();
  const [openSubItem, setOpenSubItem] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const location = useLocation();
  const isActive = subItems?.some(
    (item: any) => item.path?.split("?")[0] === location.pathname
  );
  const isActiveSubItem = useMemo(
    () => (pathname: string) => pathname.split("?")[0] === location.pathname,
    [location.pathname]
  );

  // useEffect(() => {
  //   setOpenSubItem(false);
  // }, [openSideBarState]);
  return (
    <Fragment>
      {openSideBarState ? (
        <Fragment>
          <ListItem
            button
            sx={{
              width: "100%",
              height: "40px",
              my: "20px",
              padding: "10px 12px 10px 12px",
              borderRadius: "8px",
              background: openSubItem && "#FAFAFA",
              border: openSubItem && "1px solid #EDF1F6",
              color: openSubItem && "#0A2540",
            }}
            onClick={() => setOpenSubItem(!openSubItem)}
          >
            <ListItemIcon
              sx={{
                marginRight: !openSideBarState ? 0 : "16px",
                width: "16px",
              }}
            >
              <ReactSVG
                src={icon}
                style={{ marginTop: "5px" }}
                beforeInjection={(svg) => {
                  svg.classList.add("svg-class");
                  svg.children[0].setAttribute(
                    "style",
                    openSubItem ? "fill: #0A2540" : "fill: #86909F"
                  );
                }}
              />
            </ListItemIcon>
            {openSideBarState && (
              <ListItemText
                primary={t(title)}
                sx={{ "& .MuiListItemText-primary": { fontWeight: 500 } }}
              />
            )}
            {openSideBarState &&
              subItems &&
              (openSubItem ? (
                <ExpandLess style={{ fill: "#0A2540" }} />
              ) : (
                <ExpandMore style={{ fill: "#86909F" }} />
              ))}
          </ListItem>
          {subItems && (
            <SidebarSubItems
              disable={disable}
              subitems={subItems}
              openSubItem={openSubItem}
              openSideBar={openSideBarState}
              setOpenSubItem={setOpenSubItem}
            />
          )}
        </Fragment>
      ) : (
        <Fragment>
          <ListItem
            button
            className="button_class"
            sx={{
              width: "45px",
              height: "40px",
              my: "20px",
              padding: "10px 12px 10px 12px",
              borderRadius: "8px",
              background: openSubItem || isActive ? "#FF5733" : "#FAFAFA",
              border: openSubItem && "1px solid #EDF1F6",
              justifyContent: !openSideBarState && "center",
              alignItems: !openSideBarState && "center",
              mx: "auto",
              "&:hover": {
                background: isActive ? "#FF5733" : "#FF5733",
              },
            }}
            onClick={(e) => {
              setOpenSubItem(!openSubItem);
              setAnchorEl(e.currentTarget);
            }}
            id="demo-positioned-button"
            aria-controls={openSubItem ? "demo-positioned-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openSubItem ? "true" : undefined}
          >
            {/* <Badge badgeContent={100} sx={{ "& .MuiBadge-badge": { top: "-7px", right: "-10px", background: "#FF5733", color: "#FFFF" } }}> */}
            <ListItemIcon sx={{ marginRight: !openSideBarState ? 0 : "16px" }}>
              <ReactSVG
                src={icon}
                style={{ marginTop: "5px" }}
                beforeInjection={(svg) => {
                  svg.classList.add("svg-class");
                  svg.classList.add("svg-class-name");
                  svg.children[0].setAttribute(
                    "style",
                    openSubItem || isActive ? "fill: #FFFF" : "fill: #86909F"
                  );
                }}
              />
            </ListItemIcon>
            {/* </Badge> */}
          </ListItem>
          {subItems && (
            <SidebarSubItems
              disable={disable}
              subitems={subItems}
              openSubItem={openSubItem}
              openSideBar={openSideBarState}
              setOpenSubItem={setOpenSubItem}
            />
          )}
          {/* {subItems && (
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={openSubItem}
              onClose={() => setOpenSubItem(false)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              sx={{
                "& .MuiPaper-root": {
                  left: "86px !important",
                  padding: "10px",
                },
              }}
            >
              {subItems?.map((item) => (
                <ListItem
                  button
                  component={Link}
                  to={item.path}
                  sx={{
                    "& .MuiListItemText-root::before": openSideBarState && {
                      content: "''",
                      borderRadius: "0px 0px 0px 8px",
                      borderBottom: "3px solid #86909F",
                      borderLeft: "3px solid #86909F",
                      width: "13px",
                      height: "18px",
                      position: "absolute",
                      top: 5,
                      left: "-15px",
                    },
                    my: "10px",
                    padding: "8px 12px",
                    background: isActiveSubItem(item.path)
                      ? "#FF5733"
                      : "transparent",
                    color: isActiveSubItem(item.path) ? "#FFFFFF" : "#86909F",
                    borderRadius: "8px",
                    height: "40px",
                    "&:hover": {
                      background: "#FF5733",
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <ListItemText primary={item.title} />
                </ListItem>
              ))}
            </Menu>
          )} */}
        </Fragment>
      )}
    </Fragment>
  );
};

export default NavSection;
