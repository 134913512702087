import { gql } from "@apollo/client";

const UPDATE_SPORTS = gql`
  mutation updateSport(
    $id: Int!
    $icon: Upload
    $title: String!
    $abbreviation: String
    $resourceTitle: String
    $enableDiscipline: Boolean
    $enableCourts: Boolean
    $enableStages: Boolean
    $displayDraws: Boolean
    $displayGroups: Boolean
    $displayLiveStandings: Boolean
    $displayOrderPlay: Boolean
    $displayListResults: Boolean
    $displayPendingResults: Boolean
    $displayTournamentResults: Boolean
    $isEnablePairingStandings: Boolean
    $isEnablePairings: Boolean
    $isEnableRounds: Boolean
    $textBox: String
    $isDisplayAthleteChessTitle: Boolean
    $isDisplaySeeds: Boolean
    $isDisplaySegments: Boolean
  ) {
    updateSport(
      updateSportInput: {
        id: $id
        title: $title
        icon: $icon
        abbreviation: $abbreviation
        resourceTitle: $resourceTitle
        enableDiscipline: $enableDiscipline
        enableCourts: $enableCourts
        enableStages: $enableStages
        displayDraws: $displayDraws
        displayGroups: $displayGroups
        displayLiveStandings: $displayLiveStandings
        displayOrderPlay: $displayOrderPlay
        displayListResults: $displayListResults
        displayPendingResults: $displayPendingResults
        displayTournamentResults: $displayTournamentResults
        isEnablePairingStandings: $isEnablePairingStandings
        isEnablePairings: $isEnablePairings
        isEnableRounds: $isEnableRounds
        textBox: $textBox
        isDisplayAthleteChessTitle: $isDisplayAthleteChessTitle
        isDisplaySeeds: $isDisplaySeeds
        isDisplaySegments: $isDisplaySegments
      }
    ) {
      id
      title
      icon
      abbreviation
      enableDiscipline
      enableCourts
      enableStages
      displayDraws
      displayGroups
      displayLiveStandings
      displayOrderPlay
      displayListResults
      displayPendingResults
      displayTournamentResults
      textBox
      resourceTitle
    }
  }
`;

export default UPDATE_SPORTS;
