import { gql } from "@apollo/client";

const TOURNAMENT_BY_ID = gql`
  query ($id: ID!) {
    tournament(id: $id) {
      id
      title
      startDate
      description
      entryDeadline
      timeZone
      termsAndConditions
      regulations
      courts{
         name
    }
      registeredUsersInTournament
      closeRegistration
      approvedEntries
      partnerSearch
      loggedInUser
      status
      onlinePayments
      endDate
      country
      address
      currency
      type
      club
      city
      entryFee
      license
      registerLink
      website
      withdrawlDeadline
      coverPhoto
      coverPhoto
      regulations
      status
      tournamentCategory {
        category {
          name
          type
          gender
          order
          discipline
          enableMetrics
          team
          isAllowed
          moveToSingles
          categoryEntries
          enableLicense
          status
          ageLimit
          ageType
          id
        }
        id
        fee
        currency
      }
      additionalQuestion {
        id
        question
      }
      league {
        league {
          id
        }
      }
      organizer {
        user {
          id
          name
        }
      }
      referee {
        referee {
          id
          name
        }
      }
      additionalReferee {
        referee {
          id
          name
        }
      }
    }
  }
`;

export default TOURNAMENT_BY_ID;
