import { gql } from "@apollo/client";

const UPDATE_ADDITIONAL_QUESTION = gql`
  mutation updateAdditionalQuestion(
    $id: Int!
    $question: String!
    $license: Int!
    $fieldType: String
    $fieldOptions: String
    $askEachAthlete: Boolean!
    $isMandatory: Boolean
  ) {
    updateAdditionalQuestion(
      updateAdditionalQuestionInput: {
        id: $id
        question: $question
        license: $license
        fieldType: $fieldType
        fieldOptions: $fieldOptions
        askEachAthlete: $askEachAthlete
        isMandatory: $isMandatory
      }
    ) {
      id
      question
      fieldType
      fieldOptions
      askEachAthlete
      isMandatory
    }
  }
`;

export default UPDATE_ADDITIONAL_QUESTION;
