import { gql } from "@apollo/client";

const DELETE_RANKING_CATEGORIES = gql`
  mutation removeLeagueCategory($ids: [Int!]!) {
    removeLeagueCategory(ids: $ids) {
      league{
        id
      }
    }
  }
`;

export default DELETE_RANKING_CATEGORIES;
