import { gql } from "@apollo/client";

const UPDATE_PARTNER_POSITION = gql`
  mutation updatePartnerSorting($partners: [JSONObject!]) {
    updatePartnerSorting(updatePartnerInput: { partners: $partners }) {
      id
    }
  }
`;

export default UPDATE_PARTNER_POSITION;
