import { gql } from "@apollo/client";

const UPDATE_COACH = gql`
  mutation updatePlatformCoach(
    $id: Int!
    $category: String
    $sport: Int
    $dateFrom: String
    $dateTo: String
    $displayOnLandingPage: Boolean
    $clubs: [Int!]
    $status: String
  ) {
    updatePlatformCoach(
      updatePlatformCoachInput: {
        id: $id
        category: $category
        sport: $sport
        dateFrom: $dateFrom
        dateTo: $dateTo
        displayOnLandingPage: $displayOnLandingPage
        clubs: $clubs
        status: $status
      }
    ) {
      id
      category
      dateFrom
      dateTo
      displayOnLandingPage
    }
  }
`;

export default UPDATE_COACH;
