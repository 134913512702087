import { gql } from "@apollo/client";

const UPDATE_LEAGUE = gql`
  mutation updateLeague(
    $id: Int!
    $title: String
    $type: String
    $abbreviation: String
    $priority: String
    $abbreviationLogo: Upload
    $platform: Int
    $sports: Int
    $discipline: String
    $enableRankings: Boolean
    $enableLicense: Boolean
    $enableOnlinePayments: Boolean
    $status: String
    $email: String
    $website: String
    $phone: String
    $instagram: String
    $country: String
    $file: Upload
    $federation: Float
    $isFeatured: Boolean
  ) {
    updateLeague(
      updateLeagueInput: {
        id: $id
        title: $title
        type: $type
        abbreviation: $abbreviation
        priority: $priority
        abbreviationLogo: $abbreviationLogo
        sports: $sports
        discipline: $discipline
        platform: $platform
        enableRankings: $enableRankings
        enableLicense: $enableLicense
        enableOnlinePayments: $enableOnlinePayments
        status: $status
        email: $email
        website: $website
        phone: $phone
        instagram: $instagram
        logo: $file
        country: $country
        federation: $federation
        isFeatured: $isFeatured
      }
    ) {
      id
      title
      type
      enableRankings
      enableLicense
      enableOnlinePayments
      status
      email
      website
      phone
      instagram
      abbreviation
      priority
      discipline
      logo
      abbreviationLogo
    }
  }
`;

export default UPDATE_LEAGUE;
