import { gql } from "@apollo/client";

const UPDATE_THIRD_PARTY_LICENSE = gql`
  mutation updateThirdPartyLicense(
    $id: Int!
    $title: String!
    $type: String!
    $sports: [Int!]
    $organizationName: String!
    $organizationAbbreviation: String
    $endPointLink: String
    $isRequiredInUserProfile: Boolean
    $isRequiredInUserRegistration: Boolean
    $isMakeLicenceMandatory: Boolean
  ) {
    updateThirdPartyLicense(
      updateThirdPartyLicenseInput: {
        id: $id
        title: $title
        type: $type
        sports: $sports
        organizationName: $organizationName
        organizationAbbreviation: $organizationAbbreviation
        endPointLink: $endPointLink
        isRequiredInUserProfile: $isRequiredInUserProfile
        isRequiredInUserRegistration: $isRequiredInUserRegistration
        isMakeLicenceMandatory: $isMakeLicenceMandatory
      }
    ) {
      id
    }
  }
`;

export default UPDATE_THIRD_PARTY_LICENSE;
