import { gql } from "@apollo/client";

const ADD_CATEGORY = gql`
  mutation createRankingPointCategory(
    $tournamentCategory: Int!
    $rankingPoint: Int!
  ) {
    createRankingPointCategory(
      createRankingPointCategoryInput: {
        tournamentCategory: $tournamentCategory
        rankingPoint: $rankingPoint
      }
    ) {
      id
    }
  }
`;
export default ADD_CATEGORY;
