import { gql } from "@apollo/client";

const DELETE_RANKING_POINT = gql`
  mutation removeRankingCategoryPoint($ids: [Int!]!) {
    removeRankingCategoryPoint(ids: $ids) {
      points
    }
  }
`;

export default DELETE_RANKING_POINT;
