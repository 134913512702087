import { gql } from "@apollo/client";

const LEAGUES = gql`
  query leagues($federationId: Int, $filter: ListLeagueInput!) {
    leagues(federationId: $federationId, filter: $filter) {
      title
      type
      status
      sports {
        id
      }
      email
      website
      id
      logo
      abbreviationLogo
      athlete {
        athlete {
          email
          dob
        }
      }
      tournaments {
        id
      }
    }
  }
`;

export default LEAGUES;
