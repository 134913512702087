import { gql } from "@apollo/client";

const CREATE_ADDITIONAL_QUESTION = gql`
  mutation createAdditionalQuestion(
    $question: String!
    $license: Int!
    $fieldType: String
    $fieldOptions: String
    $askEachAthlete: Boolean!
    $isMandatory: Boolean
  ) {
    createAdditionalQuestion(
      createAdditionalQuestionInput: {
        question: $question
        license: $license
        fieldType: $fieldType
        fieldOptions: $fieldOptions
        askEachAthlete: $askEachAthlete
        isMandatory: $isMandatory
      }
    ) {
      id
      question
      fieldType
      fieldOptions
      askEachAthlete
      isMandatory
    }
  }
`;

export default CREATE_ADDITIONAL_QUESTION;
