import { gql } from "@apollo/client";

const TOURNAMENTS = gql`
  query allTournamentNames(
    $organization: Int
    $page: Int
    $limit: Int
    $filter: ListTournamentInput
    $platform: Int
  ) {
    allTournamentNames(
      organization: $organization
      page: $page
      limit: $limit
      filter: $filter
      platform: $platform
    ) {
      tournaments {
        id
        title
        address
        startDate
        endDate
        country
        city
        coverPhoto
        withdrawlDeadline
        type
        status
        additionalQuestion {
          id
          question
        }
        tournamentCategory {
          id
          category {
            name
          }
          fee
        }
        organizer {
          member {
            name
          }
        }
        referee {
          referee {
            name
          }
        }
        additionalReferee {
          referee {
            name
          }
        }
      }
      total
    }
  }
`;

export default TOURNAMENTS;
