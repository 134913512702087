import { gql } from "@apollo/client";

const BANNER_BY_ID = gql`
  query banner($id: Int!) {
    banner(id: $id) {
      id
      headLine
      subHeadLine
      buttonText
      buttonLink
      role
      status
      primaryColor
      images
      sports {
        id
        title
      }
    }
  }
`;

export default BANNER_BY_ID;
