import { gql } from "@apollo/client";

const CREATE_RANKING_TOURNAMENT = gql`
  mutation createCategoryTournament($category: Int!, $tournament: Int!) {
    createCategoryTournament(
      createCategoryTournamentInput: {
        category: $category
        tournament: $tournament
      }
    ) {
      id
    }
  }
`;
export default CREATE_RANKING_TOURNAMENT;
