import { gql } from "@apollo/client";

const ADD_LEAGUE_ROLE = gql`
  mutation createLeagueMemberRole(
    $role: Int!
    $user: Int!
    $league: Int!
    $leagueMember: Int!
  ) {
    createLeagueMemberRole(
      createLeagueMemberRoleInput: {
        role: $role
        user: $user
        league: $league
        leagueMember: $leagueMember
      }
    ) {
      id
    }
  }
`;

export default ADD_LEAGUE_ROLE;
