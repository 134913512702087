import { gql } from "@apollo/client";

const LEAGUE_MEMBERS = gql`
  query leagueMembers(
    $organization: Int
    $platform: Int
    $filter: ListLeagueMemberInput
  ) {
    leagueMembers(
      organization: $organization
      platform: $platform
      filter: $filter
    ) {
      id
      member {
        email
        name
        surname
        dob
        email
        nation
        phone
        role
        id
      }
      league {
        id
        title
      }
      role {
        role {
          name
        }
      }
    }
  }
`;

export default LEAGUE_MEMBERS;
