import { gql } from "@apollo/client";

const CREATE_TEAM = gql`
  mutation createTeam(
    $title: String
    $country: String
    $status: String
    $sport: Int
    $club: Int
    $members: [JSONObject!]
    $discipline: Int
    $image: Upload
    $platform: Int
  ) {
    createTeam(
      createTeamInput: {
        title: $title
        country: $country
        status: $status
        sport: $sport
        club: $club
        members: $members
        discipline: $discipline
        image: $image
        platform: $platform
      }
    ) {
      id
      title
      status
      country
    }
  }
`;

export default CREATE_TEAM;

// mutation {
//     createTeam(
//       createTeamInput: {
//         title: "daig gang"
//         country: "pak"
//         status:"active"
//         sport: "gulli danda"
//         club: "night",
//         members: [ {member: 1, role:"coach"}, {member: 3, role:"captain"}]
//         discipline: "nhi ata"
//       }
//     ) {
//       title
//       status
//     }
//   }
