import { gql } from "@apollo/client";

const UPDATE_CATEGORY_POINTS = gql`
  mutation updateRankingCategoryPoint(
    $id: Int!
    $place: String!
    $points: Int!
    $numPlaces: Int!
    $segment: String
    $bracketType: String
  ) {
    updateRankingCategoryPoint(
      updateRankingCategoryPointInput: {
        id: $id
        place: $place
        points: $points
        numPlaces: $numPlaces
        bracketType: $bracketType
        segment: $segment
      }
    ) {
      id
    }
  }
`;

export default UPDATE_CATEGORY_POINTS;
