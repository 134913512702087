import { gql } from "@apollo/client";

const UPDATE_LEAGUE_RANKING_ENTRIES = gql`
  mutation updateLeagueRankings($league: Int, $date: String, $rankingCategory: Int!, $isPublic: Boolean) {
    updateLeagueRankings(updateLeagueRankingInput: { league: $league, date: $date, rankingCategory: $rankingCategory, isPublic: $isPublic }) {
      id
      isPublic
      date
    }
  }
`;

export default UPDATE_LEAGUE_RANKING_ENTRIES;
