import { gql } from "@apollo/client";

const THIRD_PARTY_LICENSE_BY_ID = gql`
  query thirdPartyLicense($id: Int!) {
    thirdPartyLicense(id: $id) {
      id
      title
      organizationName
      organizationAbbreviation
      sports {
        id
        title
      }
      type
      endPointLink
      isRequiredInUserProfile
      isRequiredInUserRegistration
      isMakeLicenceMandatory
    }
  }
`;

export default THIRD_PARTY_LICENSE_BY_ID;
