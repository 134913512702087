import { gql } from "@apollo/client";

const GET_DOCUMENTS = gql`
  query platformDocuments($filter: ListPlatformDocumentInput) {
    platformDocuments(filter: $filter) {
      id
      document
      isLeaguePage
      isTournamentCalender
      isRankings
      name
    }
  }
`;

export default GET_DOCUMENTS;
