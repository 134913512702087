import type { FC } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import type { DropzoneOptions } from "react-dropzone";
import {
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DuplicateIcon from "src/icons/Duplicate";
import XIcon from "src/icons/X";
import bytesToSize from "src/utils/bytesToSize";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
interface FileDropzoneProps extends DropzoneOptions {
  files?: any[];
  onRemove?: (file: any) => void;
  onRemoveAll?: () => void;
  onUpload?: () => void;
}
const UploadFiles: FC<FileDropzoneProps> = (props) => {
  const {
    accept,
    disabled,
    files,
    getFilesFromEvent,
    maxFiles,
    maxSize,
    minSize,
    noClick,
    noDrag,
    noDragEventsBubbling,
    noKeyboard,
    onDrop,
    onDropAccepted,
    onDropRejected,
    onFileDialogCancel,
    onRemove,
    onRemoveAll,
    onUpload,
    preventDropOnDocument,
    ...other
  } = props;
  // We did not add the remaining props to avoid component complexity
  // but you can simply add it if you need to.
  const { t } = useTranslation();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    minSize,
    onDrop,
  });
  const allowedExtensions = ["pdf", "docx", "doc"];
  return (
    <div {...other}>
      <Box
        sx={{
          display: "flex",
          height: "58px",
          padding: "14px 16px",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "stretch",
          borderRadius: "8px",
          border: "1px dashed #E3E3E4",
          background: "#FBFBFB",
          textAlign: "center",
          //   pt: 4,
          pb: 1,
          ...(isDragActive && {
            backgroundColor: "action.active",
            opacity: 0.5,
          }),
          "&:hover": {
            backgroundColor: "action.hover",
            cursor: "pointer",
            opacity: 0.5,
          },
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Typography
          sx={{
            color: "#86909F",

            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "18px",
            textAlign: "center",
          }}
          variant="h4"
        >
          {t("Upload file here")}
        </Typography>
      </Box>
      {files?.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <List>
            {files.map((file) => (
              <ListItem
                key={file.path}
                sx={{
                  border: 1,
                  borderColor: "divider",
                  borderRadius: 1,
                  "& + &": {
                    mt: 1,
                  },
                }}
              >
                {allowedExtensions?.includes(
                  file?.type?.split("/").pop().toLowerCase()
                ) ? (
                  <ListItemIcon>
                    <DuplicateIcon fontSize="small" />
                  </ListItemIcon>
                ) : (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    height="40px"
                    width="50px"
                    style={{
                      objectFit: "contain",
                      borderRadius: "10px",
                      marginRight: "10px",
                    }}
                  />
                )}
                <ListItemText
                  className="image-name"
                  primary={
                    file?.name?.split("-")[file?.name?.split("-")?.length - 1]
                  }
                  primaryTypographyProps={{
                    color: "textPrimary",
                    variant: "subtitle2",
                  }}
                  secondary={bytesToSize(file.size)}
                />
                <Tooltip title="Remove">
                  <IconButton
                    edge="end"
                    onClick={() => onRemove && onRemove(file)}
                  >
                    <XIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </div>
  );
};
UploadFiles.propTypes = {
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  disabled: PropTypes.bool,
  files: PropTypes.array,
  getFilesFromEvent: PropTypes.func,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  onUpload: PropTypes.func,
  preventDropOnDocument: PropTypes.bool,
};
UploadFiles.defaultProps = {
  files: [],
};
export default UploadFiles;
