import { gql } from "@apollo/client";

const UPDATE_TEAM = gql`
  mutation updateTeam(
    $id: Int!
    $title: String
    $country: String
    $status: String
    $sport: Int
    $club: Int
    $discipline: Int
    $image: Upload
  ) {
    updateTeam(
      updateTeamInput: {
        id: $id
        title: $title
        country: $country
        status: $status
        sport: $sport
        club: $club
        discipline: $discipline
        image: $image
      }
    ) {
      id
      title
      status
      country
      image
    }
  }
`;

export default UPDATE_TEAM;
