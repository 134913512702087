import { gql } from "@apollo/client";

const ATHLETES = gql`
  query athletes($filter: ListAthleteInput) {
    athletes(page: 1, limit: 20, filter: $filter) {
      id
      athlete {
        id
        name
        surname
        email
        phone
        nation
        gender
        city
        user_address
        user_join_club
        national_fisher_id
        user_coach {
          id
        }
        role
        password
        dob
        avatar
      }
    }
  }
`;

export default ATHLETES;
