import type { FC } from "react";
import { ReactSVG } from "react-svg";
import { Box, Divider, Typography, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface BannerProps {
  isSubmitting: boolean;
  id?: string;
  isFound?: boolean;
  handleAdd?: any;
  path: string;
  title: string;
  SecText: string;
  loading?: boolean;
  loader?: boolean;
}

const useStyles = makeStyles((theme) => ({
  button: {
    background:
      "linear-gradient(100deg, #FF5733 0.25%, #FD4C26 46.18%, #FA8D76 97.15%)",
  },
}));

const SubmitBanner: FC<BannerProps> = ({
  isSubmitting,
  isFound,
  handleAdd,
  path,
  title,
  id,
  SecText,
  loading,
  loader,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          position: "sticky",
          top: 10,
          backgroundColor: "white",
          pt: 1,
          zIndex: 2,
        }}
      >
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Typography color="textPrimary" variant="h2" sx={{ fontWeight: 600 }}>
            {`${id ? SecText : title}  `}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box
            sx={{
              m: -1,
              my: {
                md: 0,
                xs: 1,
              },
              display: "flex",
              flexWrap: {
                md: "nowrap",
                xs: "wrap",
              },

              justifyContent: {
                sm: "flex-end",
                xs: "space-between",
              },
            }}
          >
            <Button
              color="primary"
              component={RouterLink}
              to={path}
              sx={{
                padding: "5px 20px",
                height: "36px",
                backgroundColor: "#F6F6F6",
                color: "#1B386199",
                textTransform: "uppercase",
                border: "1px solid white",
                fontSize: "11px",
              }}
              variant="outlined"
            >
              {t("DISCARD AND CANCEL")}
            </Button>
            {isFound ? (
              <Button
                color="primary"
                disabled={loader}
                type="submit"
                variant="contained"
                className={classes.button}
                sx={{
                  ml: 1,
                  height: "36px",
                  fontSize: "11px",
                }}
              >
                {`${id ? t("SAVE") : t("SUBMIT")}  `}
              </Button>
            ) : (
              <Button
                color="primary"
                onClick={handleAdd}
                // disabled={isSubmitting}
                disabled={loading}
                variant="contained"
                type="submit"
                className={classes.button}
                sx={{
                  ml: 1,
                  height: "36px",
                  fontSize: "11px",
                }}
              >
                {t("SUBMIT")}
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ mb: 2, mt: 1 }} />
        </Grid>
      </Grid>
    </>
  );
};

export default SubmitBanner;
