import { gql } from "@apollo/client";
const GET_TOURNAMENT_RANKINGS = gql`
  query tournamentRankings($page: Int, $limit: Int, $filter: ListTournamentRankingInput!, $tournamentName: String) {
    tournamentRankings(page: $page, limit: $limit, filter: $filter, tournamentName: $tournamentName) {
      tournamentRankings {
        id
        name
        isPublish
        createdAt
        tournament {
          id
          title
          startDate
          endDate
        }
        tournamentCategory {
          id
          category {
            name
            id
          }
        }
        # segment
        rankings {
          id
          place
          points
          entry {
            id
            # registrationRequest {
            id
            users {
              id
              user {
                id
                email
                name
                surname
                avatar
                nation
              }
            }
          }
          # }
        }
      }
      total
    }
  }
`;
export default GET_TOURNAMENT_RANKINGS;
