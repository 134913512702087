import { Suspense, lazy } from "react";
import type { RouteObject } from "react-router";
import AuthGuard from "./components/AuthGuard";
import BrowseLayout from "./components/BrowseLayout";
import DashboardLayout from "./components/dashboard/DashboardLayout";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import AuthLayout from "./components/authentication/AuthLayout";
import Disciplines from "./pages/dashboard/Disciplines";
import DisciplineCreate from "./pages/dashboard/DisciplineCreate";
import PlatformUsers from "./pages/dashboard/platformUsers";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Browse pages

const BrowseCharts = Loadable(
  lazy(() => import("./pages/browse/BrowseCharts"))
);

const BrowseQuickStats = Loadable(
  lazy(() => import("./pages/browse/BrowseQuickStats"))
);

const Instructions = Loadable(
  lazy(() => import("./pages/dashboard/instructions"))
);
const Sports = Loadable(lazy(() => import("./pages/dashboard/Sports")));
const ThirdPartyLicense = Loadable(
  lazy(() => import("./pages/dashboard/thirdPartyLicense"))
);

const SportsCreate = Loadable(
  lazy(() => import("./pages/dashboard/SportsCreate"))
);
const CreateThirdPartyLicense = Loadable(
  lazy(() => import("./pages/dashboard/CreateThirdPartyLicense"))
);

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const PasswordRecovery = Loadable(
  lazy(() => import("./pages/authentication/PasswordRecovery"))
);
const PasswordReset = Loadable(
  lazy(() => import("./pages/authentication/PasswordReset"))
);
const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
const VerifyCode = Loadable(
  lazy(() => import("./pages/authentication/VerifyCode"))
);

// Dashboard pages

const Finance = Loadable(lazy(() => import("./pages/dashboard/Finance")));

const Overview = Loadable(lazy(() => import("./pages/dashboard/Overview")));
const PendingTournaments = Loadable(
  lazy(() => import("./pages/dashboard/pendingTournament"))
);

const PlatformTournaments = Loadable(
  lazy(() => import("./pages/dashboard/platformTournaments"))
);

const RankingDetails = Loadable(
  lazy(() => import("./pages/dashboard/RankingDetails"))
);
const RankingsHistory = Loadable(
  lazy(() => import("./pages/dashboard/RankingHistory"))
);

const TournamentResults = Loadable(
  lazy(() => import("./pages/dashboard/TournamentResults"))
);

// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import("./pages/AuthorizationRequired"))
);
const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

// Tournament pages

const TournamentCreate = Loadable(
  lazy(() => import("./pages/dashboard/TournamentCreate"))
);
// const ProjectCreate = Loadable(lazy(() => import('./pages/dashboard/ProjectCreate')));
// const ProjectDetails = Loadable(lazy(() => import('./pages/dashboard/ProjectDetails')));

//Leagues Pages
const Leagues = Loadable(lazy(() => import("./pages/dashboard/Leagues")));
const LeaguesCreate = Loadable(
  lazy(() => import("./pages/dashboard/LeagueCreate"))
);
const LeagueMembers = Loadable(
  lazy(() => import("./pages/dashboard/LeagueMembers"))
);
const MemberCreate = Loadable(
  lazy(() => import("./pages/dashboard/MemberCreate"))
);

// Users
const Users = Loadable(lazy(() => import("./pages/dashboard/Users")));

// @ts-ignore
const CreateUser = Loadable(lazy(() => import("./pages/dashboard/CreateUser")));

// Tour Categories
const TourCategories = Loadable(
  lazy(() => import("./pages/dashboard/TourCategories"))
);
const TourCatCategories = Loadable(
  lazy(() => import("./pages/dashboard/TourCatCreate"))
);

// Tournament Categories
const TournamentCategories = Loadable(
  lazy(() => import("./pages/dashboard/TournamentCategories"))
);
const TournamentCategoriesCreate = Loadable(
  lazy(() => import("./pages/dashboard/TournamentCategoriesCreate"))
);

// Federation Account
const Federation = Loadable(lazy(() => import("./pages/dashboard/Federation")));
const PlatformMembers = Loadable(
  lazy(() => import("./pages/dashboard/PlatformMembers"))
);
const SubscriptionCreate = Loadable(
  lazy(() => import("./pages/dashboard/PricingCreate"))
);
const Subscriptions = Loadable(
  lazy(() => import("./pages/dashboard/subscription"))
);

const PricingCreate = Loadable(lazy(() => import("./pages/dashboard/Pricing")));

const Coaches = Loadable(lazy(() => import("./pages/dashboard/Coaches")));
const Clubs = Loadable(lazy(() => import("./pages/dashboard/Clubs")));
const News = Loadable(lazy(() => import("./pages/dashboard/News")));
const ClubCreate = Loadable(
  lazy(() => import("./pages/dashboard/ClubsCreate"))
);
const NewsCreate = Loadable(lazy(() => import("./pages/dashboard/NewsCreate")));
const CoachesCreate = Loadable(
  lazy(() => import("./pages/dashboard/CoachesCreate"))
);

const FederationCreate = Loadable(
  lazy(() => import("./pages/dashboard/FederationCreate"))
);
const PlatformMemberCreate = Loadable(
  lazy(() => import("./pages/dashboard/platformMemberCreate"))
);

//Athletes

const Athletes = Loadable(lazy(() => import("./pages/dashboard/Athletes")));
const AthleteCreate = Loadable(
  lazy(() => import("./pages/dashboard/AthleteCreate"))
);
const Analytics = Loadable(lazy(() => import("./pages/dashboard/Analytics")));

//Rankings
const Rankings = Loadable(lazy(() => import("./pages/dashboard/Rankings")));

//Partners
const Partners = Loadable(lazy(() => import("./pages/dashboard/Partners")));
const PlatformPartners = Loadable(
  lazy(() => import("./pages/dashboard/platformPartners"))
);
const PlatoformPartnersCreate = Loadable(
  lazy(() => import("./pages/dashboard/createPlatformPartner"))
);
const LeaguePartnersCreate = Loadable(
  lazy(() => import("./pages/dashboard/leaguePartnerCreate"))
);

const LeaguePartners = Loadable(
  lazy(() => import("./pages/dashboard/LeaguePartners"))
);

const Documents = Loadable(lazy(() => import("./pages/dashboard/documents")));
const Gallery = Loadable(lazy(() => import("./pages/dashboard/gallery")));

const PartnersCreate = Loadable(
  lazy(() => import("./pages/dashboard/PartnersCreate"))
);
const Banners = Loadable(lazy(() => import("./pages/dashboard/Banners")));
const BannerCreate = Loadable(
  lazy(() => import("./pages/dashboard/BannerCreate"))
);

const DocumentCreate = Loadable(
  lazy(() => import("./pages/dashboard/createDocument"))
);
const GalleryCreate = Loadable(
  lazy(() => import("./pages/dashboard/GalleryCreate"))
);

// Licenses
const Licenses = Loadable(lazy(() => import("./pages/dashboard/Licenses")));
const LicenseCreate = Loadable(
  lazy(() => import("./pages/dashboard/LicensesCreate"))
);

//Platforms
const Platforms = Loadable(lazy(() => import("./pages/dashboard/Platforms")));
const PlatformCreate = Loadable(
  lazy(() => import("./pages/dashboard/PlatformCreate"))
);
const MyPlatforms = Loadable(
  lazy(() => import("./pages/dashboard/MyPlatforms"))
);
const OrganizationPlatform = Loadable(
  lazy(() => import("./pages/dashboard/organizationPlatform"))
);

//Purchased Licenses
const PurchasedLicenses = Loadable(
  lazy(() => import("./pages/dashboard/PurchasedLicenses"))
);
const PurchasedLicenseCreate = Loadable(
  lazy(() => import("./pages/dashboard/PurchasedLicenseCreate"))
);

// Teams
const Teams = Loadable(lazy(() => import("./pages/dashboard/Teams")));
const TeamCreate = Loadable(lazy(() => import("./pages/dashboard/TeamCreate")));

// Other pages
const Home = Loadable(lazy(() => import("./pages/Home")));

const routes: RouteObject[] | any = [
  {
    path: "authentication",
    element: <AuthLayout />,

    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "login-unguarded",
        element: <Login />,
      },
      {
        path: "password-recovery",
        element: <PasswordRecovery />,
      },
      {
        path: "password-reset/:email",
        element: <PasswordReset />,
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: "register-unguarded",
        element: <Register />,
      },
      {
        path: "verify-code",
        element: <VerifyCode />,
      },
    ],
  },

  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        // user_roles: [
        //   USER_ROLES.ADMIN,
        //   USER_ROLES.ORGANIZER,
        //   USER_ROLES.FEDERATION,
        //   USER_ROLES.SUPER_ADMIN,
        // ],
        // element: <Analytics />,
        element: <Instructions />,
      },

      {
        path: "coaches",
        children: [
          {
            index: true,
            element: <Coaches />,
          },
          {
            path: "new/:id?",
            element: <CoachesCreate />,
          },
        ],
      },

      {
        path: "clubs",
        children: [
          {
            index: true,
            element: <Clubs />,
          },
          {
            path: "new/:id?",
            element: <ClubCreate />,
          },
          {
            path: "edit/:id?",
            element: <ClubCreate />,
          },
        ],
      },
      {
        path: "organzaition-clubs",
        children: [
          {
            index: true,
            element: <Clubs />,
          },
          {
            path: "new/:id?",
            element: <ClubCreate />,
          },
          {
            path: "edit/:id?",
            element: <ClubCreate />,
          },
        ],
      },

      {
        path: "posts",
        children: [
          {
            index: true,
            element: <News />,
          },
          {
            path: "new/:slug?",
            element: <NewsCreate />,
          },
          {
            path: "edit/:slug?",
            element: <NewsCreate />,
          },
        ],
      },

      {
        path: "tournaments",
        children: [
          {
            index: true,
            element: <Overview />,
          },
          {
            path: "new/:id?",
            element: <TournamentCreate />,
          },
        ],
      },
      {
        path: "pending-tournaments",
        children: [
          {
            index: true,
            element: <PendingTournaments />,
          },
        ],
      },

      {
        path: "rankings",
        children: [
          {
            index: true,
            element: <RankingDetails />,
          },
        ],
      },
      {
        path: "rankings-history",
        children: [
          {
            index: true,
            element: <RankingsHistory />,
          },
        ],
      },
      {
        path: "tournament-results",
        children: [
          {
            index: true,
            element: <TournamentResults />,
          },
        ],
      },
      {
        path: "platform-tournaments",
        children: [
          {
            index: true,
            element: <PlatformTournaments />,
          },
        ],
      },
      {
        path: "platform-tournaments",
        children: [
          {
            index: true,
            element: <Overview />,
          },
        ],
      },
      {
        path: "allTournaments",
        children: [
          {
            index: true,
            element: <Overview />,
          },
          {
            path: "new/:id?",
            element: <TournamentCreate />,
          },
        ],
      },

      {
        path: "third-party-license",
        children: [
          {
            index: true,
            element: <ThirdPartyLicense />,
          },

          {
            path: "new/:id?",
            element: <CreateThirdPartyLicense />,
          },
          {
            path: "edit/:id?",
            element: <CreateThirdPartyLicense />,
          },
        ],
      },
      {
        path: "platform-third-party-license",
        children: [
          {
            index: true,
            element: <ThirdPartyLicense />,
          },
        ],
      },

      {
        path: "sports",
        children: [
          {
            index: true,
            element: <Sports />,
          },

          {
            path: "new/:id?",
            element: <SportsCreate />,
          },

          {
            path: "edit/:id?",
            element: <SportsCreate />,
          },
        ],
      },
      {
        path: "platform-sports",
        children: [
          {
            index: true,
            element: <Sports />,
          },
        ],
      },
      {
        path: "platform-disciplines",
        children: [
          {
            index: true,
            element: <Disciplines />,
          },
        ],
      },
      {
        path: "disciplines",
        children: [
          {
            index: true,
            element: <Disciplines />,
          },

          {
            path: "new/:id?",
            element: <DisciplineCreate />,
          },
          {
            path: "edit/:id?",
            element: <DisciplineCreate />,
          },
        ],
      },
      {
        path: "platform-leagues",
        children: [
          {
            index: true,
            element: <Leagues />,
          },
          {
            path: "new/:id?",
            element: <LeaguesCreate />,
          },
          {
            path: "edit/:id?",
            element: <LeaguesCreate />,
          },
        ],
      },
      {
        path: "leagues",
        children: [
          {
            index: true,
            element: <Leagues />,
          },
          {
            path: "settings/:id?",
            element: <LeaguesCreate />,
          },
          {
            path: "new/:id?",
            element: <LeaguesCreate />,
          },
          {
            path: "edit/:id?",
            element: <LeaguesCreate />,
          },
          {
            path: "instructions",
            element: <Instructions />,
          },
        ],
      },
      {
        path: "leagues-members",
        children: [
          {
            index: true,
            element: <LeagueMembers />,
          },
          {
            path: "new/:id?",
            element: <MemberCreate />,
          },
          {
            path: "edit/:id?",
            element: <MemberCreate />,
          },
        ],
      },
      {
        path: "platform-users",
        children: [
          {
            index: true,
            element: <PlatformUsers />,
          },
          {
            path: "new/:id?",
            element: <CreateUser />,
          },
          {
            path: "edit/:id?",
            element: <CreateUser />,
          },
        ],
      },
      {
        path: "users",
        children: [
          {
            index: true,
            element: <Users />,
          },
          {
            path: "new/:id?",
            element: <CreateUser />,
          },
          {
            path: "edit/:id?",
            element: <CreateUser />,
          },
        ],
      },
      {
        path: "platforms",
        children: [
          {
            index: true,
            element: <Platforms />,
          },
          {
            path: "new/:id?",
            element: <PlatformCreate />,
          },
          {
            path: "edit/:id?",
            element: <PlatformCreate />,
          },
        ],
      },
      {
        path: "my-platforms",
        children: [
          {
            index: true,
            element: <MyPlatforms />,
          },
        ],
      },

      {
        path: "platforms",
        children: [
          {
            index: true,
            element: <Leagues />,
          },
          {
            path: "settings/:id?",
            element: <PlatformCreate />,
          },
          {
            path: "new/:id?",
            element: <LeaguesCreate />,
          },
          {
            path: "instructions",
            element: <Instructions />,
          },
        ],
      },

      {
        path: "organization-platforms",
        children: [
          {
            index: true,
            element: <OrganizationPlatform />,
          },
          {
            path: "new/:id?",
            element: <PlatformCreate />,
          },
        ],
      },

      {
        path: "ranking-categories",
        children: [
          {
            index: true,
            element: <TourCategories />,
          },
          {
            path: "new/:id?",
            element: <TourCatCategories />,
          },
          {
            path: "edit/:id?",
            element: <TourCatCategories />,
          },
        ],
      },
      {
        path: "tournament-categories",
        children: [
          {
            index: true,
            element: <TournamentCategories />,
          },
          {
            path: "new/:id?",
            element: <TournamentCategoriesCreate />,
          },
          {
            path: "edit/:id?",
            element: <TournamentCategoriesCreate />,
          },
        ],
      },
      {
        path: "ranking-points",
        children: [
          {
            index: true,
            element: <Rankings />,
          },
        ],
      },

      {
        path: "platform-members",
        children: [
          {
            index: true,
            element: <PlatformMembers />,
          },
          {
            path: "new/:id?",
            element: <PlatformMemberCreate />,
          },
        ],
      },
      {
        path: "pricing",
        children: [
          {
            index: true,
            element: <PricingCreate />,
          },
          {
            path: "new/:id?",
            element: <SubscriptionCreate />,
          },
        ],
      },
      {
        path: "subscriptions",
        children: [
          {
            index: true,
            element: <Subscriptions />,
          },
        ],
      },

      {
        path: "organization-account",
        children: [
          {
            index: true,
            element: <Federation />,
          },
          {
            path: "new/:id?",
            element: <FederationCreate />,
          },
          {
            path: "edit/:id?",
            element: <FederationCreate />,
          },
          {
            path: "settings/:id?",
            element: <FederationCreate />,
          },
        ],
      },
      {
        path: "platform-organization-account",
        children: [
          {
            index: true,
            element: <Federation />,
          },
          {
            path: "new/:id?",
            element: <FederationCreate />,
          },
          {
            path: "edit/:id?",
            element: <FederationCreate />,
          },
        ],
      },
      {
        path: "athletes",
        children: [
          {
            index: true,
            element: <Athletes />,
          },
          {
            path: "new/:id?",
            element: <AthleteCreate />,
          },
          {
            path: "edit/:id?",
            element: <AthleteCreate />,
          },
        ],
      },

      {
        path: "platform-partners",
        children: [
          {
            index: true,
            element: <PlatformPartners />,
          },
          {
            path: "new/:id?",
            element: <PlatoformPartnersCreate />,
          },
          {
            path: "edit/:id?",
            element: <PlatoformPartnersCreate />,
          },
        ],
      },
      {
        path: "league-partners",
        children: [
          {
            index: true,
            element: <LeaguePartners />,
          },
          {
            path: "new/:id?",
            element: <LeaguePartnersCreate />,
          },
          {
            path: "edit/:id?",
            element: <LeaguePartnersCreate />,
          },
        ],
      },

      {
        path: "partners",
        children: [
          {
            index: true,
            element: <Partners />,
          },
          {
            path: "new/:id?",
            element: <PartnersCreate />,
          },
          {
            path: "edit/:id?",
            element: <PartnersCreate />,
          },
        ],
      },
      {
        path: "banners",
        children: [
          {
            index: true,
            element: <Banners />,
          },
          {
            path: "new/:id?",
            element: <BannerCreate />,
          },
          {
            path: "edit/:id?",
            element: <BannerCreate />,
          },
        ],
      },

      {
        path: "documents",
        children: [
          {
            index: true,
            element: <Documents />,
          },
          {
            path: "new/:id?",
            element: <DocumentCreate />,
          },
        ],
      },
      {
        path: "gallery",
        children: [
          {
            index: true,
            element: <Gallery />,
          },
          {
            path: "new/:id?",
            element: <GalleryCreate />,
          },
        ],
      },

      {
        path: "licenses",
        children: [
          {
            index: true,
            element: <Licenses />,
          },
          {
            path: "new/:id?",
            element: <LicenseCreate />,
          },
          {
            path: "edit/:id?",
            element: <LicenseCreate />,
          },
        ],
      },
      {
        path: "purchased-licenses",
        children: [
          {
            index: true,
            element: <PurchasedLicenses />,
          },
          {
            path: "new/:id?",
            element: <PurchasedLicenseCreate />,
          },
          {
            path: "edit/:id?",
            element: <PurchasedLicenseCreate />,
          },
        ],
      },

      {
        path: "teams",
        children: [
          {
            index: true,
            element: <Teams />,
          },
          {
            path: "new/:id?",
            element: <TeamCreate />,
          },
          {
            path: "edit/:id?",
            element: <TeamCreate />,
          },
        ],
      },
      {
        path: "teams",
        children: [
          {
            index: true,
            element: <Teams />,
          },
        ],
      },
      {
        path: "analytics",
        element: <Analytics />,
      },

      {
        path: "finance",
        element: <Finance />,
      },
    ],
  },

  {
    path: "*",
    // element: <MainLayout />,
    children: [
      {
        index: true,
        element: (
          <AuthGuard>
            {" "}
            <Home />{" "}
          </AuthGuard>
        ),
      },
      {
        path: "browse",
        element: <BrowseLayout />,
        children: [
          {
            index: true,
            element: <BrowseCharts />,
          },

          {
            path: "data-display/quick-stats",
            element: <BrowseQuickStats />,
          },
        ],
      },

      {
        path: "401",
        // user_roles: [
        //   USER_ROLES.ADMIN,
        //   USER_ROLES.ORGANIZER,
        //   USER_ROLES.FEDERATION,
        //   USER_ROLES.SUPER_ADMIN,
        // ],
        element: <AuthorizationRequired />,
      },
      {
        path: "404",
        element: <NotFound />,
      },
      {
        path: "500",
        element: <ServerError />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
