import { gql } from "@apollo/client";

const REGISTER = gql`
  mutation user(
    $email: String!
    $name: String!
    $surname: String!
    $password: String!
    $user_sports: [Int!]
  ) {
    signUp(
      createUserInput: {
        email: $email
        name: $name
        surname: $surname
        password: $password
        user_sports: $user_sports
      }
    ) {
      id
      email
      accessToken
      name
      surname
    }
  }
`;

export default REGISTER;
