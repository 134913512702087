import { gql } from "@apollo/client";

const ADD_PRICING = gql`
  mutation createPlan(
    $title: String!
    $planType: String!
    $price: String
    $monthlyDiscount: String
    $yearlyDiscount: String
    $currency: String!
    $features: [String!]
  ) {
    createPlan(
      createPlanInput: {
        title: $title
        planType: $planType
        price: $price
        currency: $currency
        monthlyDiscount: $monthlyDiscount
        yearlyDiscount: $yearlyDiscount
        features: $features
      }
    ) {
      id
    }
  }
`;
export default ADD_PRICING;
