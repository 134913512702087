import { gql } from "@apollo/client";

const UPDATE_FACTSHEET_POSITION = gql`
  mutation updateFactSheetSorting($factSheets: [JSONObject!]) {
    updateFactSheetSorting(updateFactSheetInput: { factSheets: $factSheets }) {
      id
    }
  }
`;

export default UPDATE_FACTSHEET_POSITION;
