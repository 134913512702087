import { gql } from "@apollo/client";

const ADD_SETTING_CATEGORY = gql`
  mutation createCategorySetting(
    $tournament: Int!
    $category: Int!
    $fee: Int!
    $method: String!
    $onlinePayments: String!
    $status: String!
    $service: Int!
    $currency: String!
  ) {
    createCategorySetting(
      createCategorySettingInput: {
        tournament: $tournament
        category: $category
        fee: $fee
        method: $method
        onlinePayments: $onlinePayments
        status: $status
        service: $service
        currency: $currency
      }
    ) {
      id
    }
  }
`;

export default ADD_SETTING_CATEGORY;
