import { gql } from "@apollo/client";

const ALLLEAGUES = gql`
  query allLeagues($filter: ListLeagueInput!) {
    allLeagues(filter: $filter) {
      title
      type
      status
      sports {
        id
      }
      email
      website
      id
      logo
      abbreviationLogo
      athlete {
        athlete {
          email
          dob
        }
      }
      tournaments {
        id
      }
    }
  }
`;

export default ALLLEAGUES;
