import { gql } from "@apollo/client";

const UPDATE_TOURNAMENT = gql`
  mutation updateTournament($id: Float!, $status: String!) {
    updateTournament(updateTournamentInput: { id: $id, status: $status }) {
      id
      title
    }
  }
`;

export default UPDATE_TOURNAMENT;
