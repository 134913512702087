import { gql } from "@apollo/client";

const TEAMS = gql`
  query teams($filter: ListTeamInput) {
    teams(filter: $filter) {
      teams {
        id
        title
        sport {
          id
          title
        }
        club {
          id
          clubName
        }
        discipline {
          id
          title
        }
        country
        status
        image
        members {
          member {
            name
          }
          team {
            title
          }
          role
        }
      }
    }
  }
`;

export default TEAMS;
