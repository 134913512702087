import { gql } from "@apollo/client";
const RANKINGS_BY_LEAGUE = gql`
  query tournamentRankings($filter: ListTournamentRankingInput!) {
    tournamentRankings(filter: $filter) {
      id
      name
      tournament {
        id
        title
      }
      tournamentCategory {
        id
        category {
          name
          id
        }
      }
      # segment
      rankings {
        id
        place
        points
        entry {
          id
          # registrationRequest {
          id
          users {
            id
            user {
              id
              email
              name
              surname
              avatar
              nation
            }
          }
        }
        # }
      }
    }
  }
`;
export default RANKINGS_BY_LEAGUE;
