import { gql } from "@apollo/client";

const BANNERS = gql`
  query banners($filter: ListBannerInput!) {
    banners(filter: $filter) {
      id
      headLine
      subHeadLine
      buttonText
      buttonLink
      images
      primaryColor
      status
      sports {
        id
      }
      role
    }
  }
`;

export default BANNERS;
