import { gql } from "@apollo/client";

const CREATE_BANNER = gql`
  mutation createBanner(
    $platform: Int!
    $headLine: String!
    $subHeadLine: String!
    $buttonText: String!
    $buttonLink: String!
    $primaryColor: String!
    $status: String!
    $role: String
    $sports: [Int!]
    $images: [Upload!]
  ) {
    createBanner(
      createBannerInput: {
        platform: $platform
        headLine: $headLine
        subHeadLine: $subHeadLine
        buttonText: $buttonText
        buttonLink: $buttonLink
        primaryColor: $primaryColor
        status: $status
        role: $role
        sports: $sports
        images: $images
      }
    ) {
      id
    }
  }
`;

export default CREATE_BANNER;
