import { gql } from "@apollo/client";

const ADD_ROLE = gql`
  mutation createUserRole($role: Int!, $user: Int!) {
    createUserRole(createUserRoleInput: { role: $role, user: $user }) {
      id
    }
  }
`;

export default ADD_ROLE;
