import { gql } from "@apollo/client";

const ADD_CATEGORY_POINTS = gql`
  mutation createRankingCategoryPoint(
    $rankingPoint: Int!
    $place: String!
    $points: Float
    $numPlaces: Int!
    $segment: String
    $bracketType: String
  ) {
    createRankingCategoryPoint(
      createRankingCategoryPointInput: {
        rankingPoint: $rankingPoint
        place: $place
        points: $points
        numPlaces: $numPlaces
        segment: $segment
        bracketType: $bracketType
      }
    ) {
      id
    }
  }
`;

export default ADD_CATEGORY_POINTS;
