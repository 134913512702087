import { gql } from "@apollo/client";

const REMOVE_NEWS = gql`
  mutation removeNews($id: Int!) {
    removeNews(id: $id) {
      id
    }
  }
`;

export default REMOVE_NEWS;
