import { useEffect, useCallback, useState } from "react";
import type { FC } from "react";
import { useRoutes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import "./i18n";
import RTL from "./components/RTL";
import SplashScreen from "./components/SplashScreen";
import { gtmConfig } from "./config";
import useAuth from "./hooks/useAuth";
import useScrollReset from "./hooks/useScrollReset";
import useSettings from "./hooks/useSettings";
import gtm from "./lib/gtm";
import routes from "./routes";
import { createCustomTheme } from "./theme";
import "./assets/fonts/montserrat/stylesheet.css";
import "./assets/fonts/roboto/stylesheet.css";
import "./assets/fonts/eudoxus-sans/stylesheet.css";
// import "./assets/fonts/Inter/stylesheet.css";
import "./assets/fonts/Inter/stylesheet.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import Cookies from "js-cookie";

const App: FC = () => {
  const { settings } = useSettings();
  const { isInitialized, user } = useAuth();
  const [userSectionsState, setUserSections] = useState(routes);

  useScrollReset();
  const tokenParam = new URLSearchParams(window.location.search).get("token");
  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);
  useEffect(() => {
    if (tokenParam) {
      Cookies.set("token", tokenParam);
    }
  }, [tokenParam]);
  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  // const selectUserSections = useCallback((user) => {
  //   if (user) {
  //     const filteredArray = routes
  //       .filter((element) =>
  //         element?.children?.some((subElement) =>
  //           subElement?.user_roles?.includes(user.role)
  //         )
  //       )
  //       .map((element) => {
  //         let n = Object.assign({}, element, {
  //           children: element.children.filter((subElement) =>
  //             subElement?.user_roles?.includes(user.role)
  //           ),
  //         });
  //         return n;
  //       });
  //     console.log(filteredArray);

  //     setUserSections(filteredArray);
  //   } else {
  //     setUserSections(routes);
  //   }
  // }, []);

  // useEffect(() => {
  //   selectUserSections(user);
  // }, [selectUserSections, user]);

  const content = useRoutes(routes);
  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        <Toaster position="top-center" />
        {isInitialized ? content : <SplashScreen />}
      </RTL>
    </ThemeProvider>
  );
};

export default App;
