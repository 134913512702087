import { gql } from "@apollo/client";

const CREATE_LICENSE = gql`
  mutation createLicense(
    $title: String!
    $fee: Float!
    $currency: String!
    $duration: String!
    $status: String!
    $leagues: [Int!]
    $startDate: DateTime!
    $endDate: DateTime!
    $tournatedLicenseFee: Float
    $partnerLicenseFee: Float
    $additionalQuestion: [JSONObject!]
  ) {
    createLicense(
      createLicenseInput: {
        title: $title
        fee: $fee
        currency: $currency
        duration: $duration
        status: $status
        leagues: $leagues
        startDate: $startDate
        endDate: $endDate
        tournatedLicenseFee: $tournatedLicenseFee
        partnerLicenseFee: $partnerLicenseFee
        additionalQuestion: $additionalQuestion
      }
    ) {
      id
      title
      fee
      currency
      duration
      status
      startDate
      endDate
    }
  }
`;

export default CREATE_LICENSE;
