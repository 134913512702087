import { gql } from "@apollo/client";

const LICENSES = gql`
  query licenses($federation: Int, $platform: Int, $filter: ListLicenseInput) {
    licenses(federation: $federation, platform: $platform, filter: $filter) {
      id
      title
      fee
      currency
      duration
      status
      startDate
      endDate
    }
  }
`;

export default LICENSES;
