import { gql } from "@apollo/client";

const PARTNER_BY_ID = gql`
  query partner($id: Int!) {
    partner(id: $id) {
      id
      title
      link
      sponsorText
      priority
      activeFrom
      activeTo
      image
      club {
        id
      }
      platform {
        id
      }
      league {
        id
      }
      federation {
        id
        organizationName
      }
    }
  }
`;

export default PARTNER_BY_ID;
