import { gql } from "@apollo/client";

const NEWS = gql`
  query allNews($filter: ListNewsInput) {
    allNews(filter: $filter) {
      news {
        id
        title
        image
        slug
        createdAt
        platform {
          title
          id
        }
        federationNews {
          id
          federation {
            id
            organizationName
          }
        }
        leagueNews {
          id
          league {
            title
          }
        }
        clubNews {
          club {
            clubName
          }
        }

        author {
          id
          email
          name
          surname
        }
      }
    }
  }
`;

export default NEWS;
