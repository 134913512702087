import { gql } from "@apollo/client";

const UPDATE_RANKING_CATEGORY_RULE = gql`
  mutation updateCategoryRule(
    $id: Int!
    $category: Int!
    $sector: String
    $numberOfTournaments: Int
    $tournamentCategories: [Int!]
    $licenseSubtypes: String
    $thirdPartyLicense: Int
    $coefficient: Float
  ) {
    updateCategoryRule(
      updateCategoryRuleInput: {
        id: $id
        category: $category
        sector: $sector
        numberOfTournaments: $numberOfTournaments
        licenseSubtypes: $licenseSubtypes
        tournamentCategories: $tournamentCategories
        thirdPartyLicense: $thirdPartyLicense
        coefficient: $coefficient
      }
    ) {
      id
    }
  }
`;

export default UPDATE_RANKING_CATEGORY_RULE;
