import { gql } from "@apollo/client";

const UPDATE_LEAGUE_RANKING_ENTRY = gql`
  mutation updateLeagueRanking($id: Int!, $isPublic: Boolean) {
    updateLeagueRanking(updateLeagueRankingInput: { id: $id, isPublic: $isPublic }) {
      id
      isPublic
      date
    }
  }
`;

export default UPDATE_LEAGUE_RANKING_ENTRY;
