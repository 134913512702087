import { gql } from "@apollo/client";

const UPDATE_POINTS_POSITION = gql`
  mutation updateRankingPoint(
    $rankingPoint: Int!
    $categoryPoints: [JSONObject!]
  ) {
    updateRankingPoint(
      updateRankingCategoryPointInput: {
        rankingPoint: $rankingPoint
        categoryPoints: $categoryPoints
      }
    ) {
      id
    }
  }
`;

export default UPDATE_POINTS_POSITION;
