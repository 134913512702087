import { gql } from "@apollo/client";

const UPDATE_SPORTS_POSITION = gql`
  mutation updateSportSorting($sports: [JSONObject!]) {
    updateSportSorting(updateSportInput: { sports: $sports }) {
      id
    }
  }
`;

export default UPDATE_SPORTS_POSITION;
