import { gql } from "@apollo/client";

const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword($email: String!, $password: String!) {
    updateUserPassword(
      updateUserpassword: { email: $email, password: $password }
    ) {
      id
      email
    }
  }
`;

export default UPDATE_USER_PASSWORD;
