import { gql } from "@apollo/client";

const ADD_FEDERATION = gql`
  # change the name of api from createFederation to createFederationByAdmin for testing purpose
  # mutation createFederation(
  mutation createFederationByAdmin(
    $federationEmail: String!
    $role: String!
    $organizationName: String!
    $organizationAbbreviation: String!
    $organizationType: String
    $logo: Upload
    $about: String
    $contactEmail: String!
    $contactPhone: String
    $website: String
    $country: String
    $tournatedPlatformId: Int!
  ) {
    # createFederation(
    createFederationByAdmin(
      createFederationInput: {
        federationEmail: $federationEmail
        role: $role
        organizationType: $organizationType
        organizationName: $organizationName
        organizationAbbreviation: $organizationAbbreviation
        logo: $logo
        about: $about
        contactEmail: $contactEmail
        contactPhone: $contactPhone
        website: $website
        country: $country
        tournatedPlatformId: $tournatedPlatformId
      }
    ) {
      id
    }
  }
`;

export default ADD_FEDERATION;
