import { gql } from "@apollo/client";

const LEAGUE_RANKINGS = gql`
  query leagueRankingsCustom($filter: ListLeagueRankingInput!, $country: String, $page: Int, $limit: Int) {
    leagueRankingsCustom(country: $country, filter: $filter, page: $page, limit: $limit) {
      rankings {
        createdAt
        date
        id
        isPublic
        league {
          id
          title
          logo
        }
        user {
          id
          name
          surname
          country
          nation
          avatar
          dob
        }
        points
        place
        tournaments
        rankingCategory {
          id
          category {
            id
            name
          }
        }
        thirdPartyPoints
      }
      total
    }
  }
`;

export default LEAGUE_RANKINGS;
