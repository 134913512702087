import { gql } from "@apollo/client";

const ADD_USER_COACH = gql`
  mutation createPlatformCoach(
    $createPlatformCoachInput: CreatePlatformCoachInput!
    $createUserInput: CreateUserInput
  ) {
    createPlatformCoach(
      createPlatformCoachInput: $createPlatformCoachInput
      createUserInput: $createUserInput
    ) {
      id
    }
  }
`;

export default ADD_USER_COACH;
