import { gql } from "@apollo/client";

const REMOVE_LEAGUE_MEMBER = gql`
  mutation removeLeagueMember($id: Int!) {
    removeLeagueMember(id: $id) {
      league {
        id
      }
    }
  }
`;

export default REMOVE_LEAGUE_MEMBER;
