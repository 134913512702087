import { useRef, useState } from "react";
import type { FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@material-ui/core";
import { ReactSVG } from "react-svg";
import { BASE_URL } from "src/config";
import useAuth from "src/hooks/useAuth";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Add } from "@material-ui/icons";

const CreatePopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleOpen = (): void => {
    setOpen(true);
  };
  const handleClose = (): void => {
    setOpen(false);
  };
  return (
    <>
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          height: "40px",
          padding: "7px 14px 7px 14px",
          borderRadius: "4px",
          backgroundColor: "#FAFAFA",
          fontSize: "14px",
          color: "#425466",
          border: `1px solid #EFEFF0`,
          "&:hover": {
            backgroundColor: "#FAFAFA",
            boxShadow: "none",
          },
        }}
        startIcon={<Add />}
      >
        <span style={{ fontSize: "14px" }}>{t("Create")}</span>
      </Button>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: 240,
            boxShadow:
              "0px 6px 9px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.16)",
            borderRadius: "16px",
          },
        }}
      >
        <MenuItem onClick={() => navigate("/dashboard/leagues/new")}>
          <ListItemText
            primary={
              <Typography color="body2" variant="subtitle2">
                {t("Add League")}
              </Typography>
            }
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open(
              `${process?.env?.REACT_APP_TOURNAMENT_URL}/dashboard/all-tournaments/new?token=${token}`
            );
          }}
        >
          <ListItemText
            primary={
              <Typography color="body2" variant="subtitle2">
                {t("Add Tournament")}
              </Typography>
            }
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(`/dashboard/clubs/new`);
          }}
        >
          <ListItemText
            primary={
              <Typography color="body2" variant="subtitle2">
                {t("Add Club")}
              </Typography>
            }
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(`/dashboard/posts/new`);
          }}
        >
          <ListItemText
            primary={
              <Typography color="body2" variant="subtitle2">
                {t("Add Post")}
              </Typography>
            }
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate(`/dashboard/platform-members/new`);
          }}
        >
          <ListItemText
            primary={
              <Typography color="body2" variant="subtitle2">
                {t("Add Team")}
              </Typography>
            }
          />
        </MenuItem>
      </Popover>
    </>
  );
};
export default CreatePopover;
