import { gql } from "@apollo/client";

const TOURNAMENT_CATEGORIES = gql`
  query tournamentCategories(
    $federation: Int
    $filter: ListTournamentCategoryInput
    $orderField: String
  ) {
    tournamentCategories(
      federation: $federation
      filter: $filter
      orderField: $orderField
    ) {
      name
      type
      gender
      order
      discipline {
        id
      }
      league {
        id
        league {
          id
          title
        }
      }
      enableMetrics
      team
      isAllowed
      moveToSingles
      categoryEntries
      enableLicense
      status
      ageLimit
      ageType
      id
    }
  }
`;

export default TOURNAMENT_CATEGORIES;
