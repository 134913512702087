import { gql } from "@apollo/client";

const DELETE_NEWS_LEAGUE = gql`
  mutation removeLeagueNews($id: Int!) {
    removeLeagueNews(id: $id) {
      league {
        id
      }
    }
  }
`;

export default DELETE_NEWS_LEAGUE;
