import { gql } from "@apollo/client";

const UPDATE_CATEGORY_POINTS_ARRAY = gql`
  mutation updateRankingCategoryPoints(
    $updateRankingCategoryPointsInput: [UpdateRankingCategoryPointInput!]!
  ) {
    updateRankingCategoryPoints(
      updateRankingCategoryPointsInput: $updateRankingCategoryPointsInput
    ) {
      id
    }
  }
`;

export default UPDATE_CATEGORY_POINTS_ARRAY;
