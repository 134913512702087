import { gql } from "@apollo/client";

const CREATE_FEDERATION = gql`
  mutation createFederationByAdmin(
    $name: String!
    $surname: String!
    $email: String!
    $phone: String
    $organizationName: String!
    $organizationAbbreviation: String!
    $organizationType: String!
    $logo: Upload
    $about: String
    $contactEmail: String!
    $contactPhone: String
    $website: String
    $password: String!
    $role: String!
    $country: String
    $tournatedPlatformId: Int!
  ) {
    createFederationByAdmin(
      createFederationInput: {
        name: $name
        surname: $surname
        email: $email
        phone: $phone
        organizationName: $organizationName
        organizationAbbreviation: $organizationAbbreviation
        organizationType: $organizationType
        logo: $logo
        about: $about
        contactEmail: $contactEmail
        contactPhone: $contactPhone
        website: $website
        password: $password
        role: $role
        country: $country
        tournatedPlatformId: $tournatedPlatformId
      }
    ) {
      organizationName
      organizationAbbreviation
      logo
      about
      contactEmail
      contactPhone
      website
      organizationType
      id
    }
  }
`;

export default CREATE_FEDERATION;
