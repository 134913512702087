import { gql } from "@apollo/client";

const ADD_LICENSE_LEAGUE = gql`
  mutation createLicenseLeague($league: Int!, $license: Int!) {
    createLicenseLeague(
      createLicenseLeagueInput: { league: $league, license: $license }
    ) {
      id
    }
  }
`;

export default ADD_LICENSE_LEAGUE;
