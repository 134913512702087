import { gql } from "@apollo/client";

const UPDATE_ORGANIZATION_PATFORM = gql`
  mutation updateOrganizationPlatform($id: Int!, $federation: Int!) {
    updateOrganizationPlatform(
      updateOrganizationPlatformInput: { id: $id, federation: $federation }
    ) {
      id
    }
  }
`;

export default UPDATE_ORGANIZATION_PATFORM;
