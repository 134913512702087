import { gql } from "@apollo/client";

const SPORTS_BY_ID = gql`
  query sport($id: Int!) {
    sport(id: $id) {
      id
      title
      icon
      resourceTitle
      abbreviation
      enableDiscipline
      enableCourts
      enableStages
      displayDraws
      displayGroups
      displayLiveStandings
      displayOrderPlay
      displayListResults
      displayPendingResults
      displayTournamentResults
      isEnablePairingStandings
      isEnablePairings
      isEnableRounds
      textBox
      isDisplaySeeds
      isDisplaySegments
      isDisplayAthleteChessTitle
    }
  }
`;

export default SPORTS_BY_ID;
