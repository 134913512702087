import { gql } from "@apollo/client";

const DELETE_RANKING_TOURNAMENT = gql`
  mutation removeCategoryTournament($id: Int!) {
    removeCategoryTournament(id: $id) {
      category {
        id
      }
    }
  }
`;

export default DELETE_RANKING_TOURNAMENT;
