import { gql } from "@apollo/client";

const PLANS = gql`
  query {
    plans(filter: {}) {
      id
      title
      price
      planType
      monthlyDiscount
      yearlyDiscount
      features
    }
  }
`;

export default PLANS;
