import { gql } from "@apollo/client";

const CREATE_RANKINGS_ENTRY = gql`
  mutation createTournamentRankingsEntry(
    $ranking: Int!
    $tournament: Int!
    $tournamentCategory: Int!
    $entry: Int
    $place: String
    $points: Int
  ) {
    createTournamentRankingsEntry(
      createTournamentRankingsEntryInput: {
        ranking: $ranking
        tournament: $tournament
        tournamentCategory: $tournamentCategory
        entry: $entry
        place: $place
        points: $points
      }
    ) {
      id
    }
  }
`;

export default CREATE_RANKINGS_ENTRY;
